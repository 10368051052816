import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import { Table, Icon } from 'semantic-ui-react'

import { format } from 'date-fns'


const RowCard = ({ rows }) => {
    const navigate = useNavigate()

    return (

        <>
            <Table>
                <Table.Header>
                <Table.Row>
                <Table.HeaderCell width={1}>Id</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Version</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Created</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Updated</Table.HeaderCell>

                    <Table.HeaderCell width={1}></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows && rows.map((row, i) => {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>{row?.id}</Table.Cell>
                                <Table.Cell>{row?.name}</Table.Cell>
                                <Table.Cell>{row?.status_text}</Table.Cell>
                                <Table.Cell>{row?.api_version_text}</Table.Cell>
                                <Table.Cell>
                                    {row?.created_at && format(new Date(row?.created_at), 'dd-MMM-yyyy hh:mm')}
                                </Table.Cell>
                                <Table.Cell>
                                    {row?.created_at && format(new Date(row?.updated_at), 'dd-MMM-yyyy hh:mm')}
                                </Table.Cell>

                                <Table.Cell>
                                <Icon style={{cursor: "pointer"}} 
                                onClick={() => navigate(`/app/story-templates/details/${row?.id}`)}
                                name='angle double right' />
                            </Table.Cell>
                        </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    )
};

RowCard.propTypes = {
    rows: PropTypes.array.isRequired,
};

export default RowCard;



const TableCell = styled.td`
    background-color: ${props => props.noResponse ? '#A8D5B7' : '#F0A8A8'}; // muted green and muted red
`;