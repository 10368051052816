import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Select from "react-select";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Button, Message } from "semantic-ui-react";

import { format, set } from "date-fns";

import { BasicErrorMessage } from "utilities/ErrorMessages";
import { SCHEDULE_TEMPLATE_TYPE } from "data/AdminData";

import {
  SG_GET_ORGANIZATION_SELECT,
  SG_GET_DEBRIEF_SCHEDULES_SELECT_LIST,
  SG_FETCH_ORGANIZATION_PRIMARY,
  SG_GET_REPORT_TEMPLATE_SELECT,
  SG_GET_RECOMMENDATION_MODELS,
  SG_GET_EMPLOYEE_SELECT_LIST,
  SG_ADD_SCHEDULE_TEMPLATE,
  SG_EDIT_SCHEDULE_TEMPLATE,
} from "constants/actions";

// status 1 Run Now
// status 9 Schedule

const DataForm = ({ templateDetails }) => {
  const dispatch = useDispatch();

  const [templateId, setTemplateId] = useState(templateDetails?.id);
  const [statusInfo, setStatusInfo] = useState(9);

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);

  const [organizationId, setOrganizationId] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const [organizationSelected, setOrganizationSelected] = useState([]);

  const [reportTemplate, setReportTemplate] = useState([]);
  const [reportTemplateSelected, setReportTemplateSelected] = useState("");

  const [recommendationModels, setRecommendationModels] = useState([]);
  const [recommendationModelSelected, setRecommendationModelSelected] =
    useState("");

  const [organizationPrimary, setOrganizationPrimary] = useState([]);
  const [organizationPrimarySelected, setOrganizationPrimarySelected] =
    useState("");

  const [debriefSurveyList, setDebriefSurveyList] = useState([]);
  const [debriefSurveySelected, setDebriefSurveySelected] = useState("");

  const [employeeList, setEmployeeList] = useState([]);
  const [employeeSelected, setEmployeeSelected] = useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [categoryListSelected, setCategoryListSelected] = useState([]);

  const [categoryOption, setCategoryOption] = useState([]);
  const [categoryOptionSelected, setCategoryOptionSelected] = useState([]);

  const [typeOfSelected, setTypeOfSelected] = useState(
    SCHEDULE_TEMPLATE_TYPE[0]
  );

  const [testOutput, setTestOutput] = useState(false);

  const [employeeAddedList, setEmployeeAddedList] = useState([]);

  const {
    get_organization,
    get_debrief_schedule,
    get_recommendation_models,
    get_report_template,
    get_employee_select_list,
  } = useSelector(
    (state) => ({
      get_organization: state.organizations,
      get_debrief_schedule: state.debrief_schedule,
      get_recommendation_models: state.recommendation_models,
      get_report_template: state.report_template,
      get_employee_select_list: state.employees.employee_select_list,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (templateDetails?.id) {
      setTemplateId(templateDetails?.id);
    }
    if (templateDetails?.organization) {
      setOrganizationId(templateDetails?.organization);
    }
  }, [templateDetails]);

  useEffect(() => {
    if (organizationSelected?.id) {
      setOrganizationId(organizationSelected?.id);
    }
  }, [organizationSelected]);

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });
    dispatch({
      type: SG_GET_RECOMMENDATION_MODELS,
    });
    dispatch({
      type: SG_GET_REPORT_TEMPLATE_SELECT,
    });
  }, [dispatch]);

  useEffect(() => {
    if (organizationId) {
      dispatch({
        type: SG_GET_EMPLOYEE_SELECT_LIST,
        payload: `organization=${organizationId}`,
      });
      dispatch({
        type: SG_GET_DEBRIEF_SCHEDULES_SELECT_LIST,
        payload: `organization=${organizationId}`,
      });
      dispatch({
        type: SG_FETCH_ORGANIZATION_PRIMARY,
        payload: { id: organizationId },
      });
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    setOrganizationList(get_organization?.organization_select);
  }, [dispatch, get_organization]);

  useEffect(() => {
    if (get_organization?.primary_select?.response) {
      setOrganizationPrimary(
        get_organization?.primary_select?.response?.filter(
          (item) => item?.priority === "primary"
        )
      );
      setCategoryList(
        get_organization?.primary_select?.response
          ?.filter((item) => item?.priority !== "primary")
          ?.map((item) => ({ name: item?.name, id: item?.id }))
      );
    }
  }, [dispatch, get_organization]);

  useEffect(() => {
    if (
      get_organization?.primary_select?.response &&
      categoryListSelected?.id
    ) {
      setCategoryOption(
        get_organization?.primary_select?.response
          ?.filter((item) => item?.id === categoryListSelected?.id)
          ?.map((item) => item?.options)[0]
      );
    }
  }, [dispatch, get_organization, categoryListSelected]);

  useEffect(() => {
    setEmployeeList(get_employee_select_list);
  }, [get_employee_select_list]);

  useEffect(() => {
    setRecommendationModels(
      get_recommendation_models?.recommendation_models?.response
    );
  }, [get_recommendation_models]);

  useEffect(() => {
    setReportTemplate(get_report_template?.select);
  }, [get_report_template]);

  useEffect(() => {
    setDebriefSurveyList(get_debrief_schedule?.select_list);
  }, [dispatch, get_debrief_schedule]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      if (templateDetails?.status) {
        setStatusInfo(templateDetails?.status);
      }
      if (templateDetails?.organization) {
        setOrganizationSelected(
          organizationList.filter(
            (o) => o.id === templateDetails?.organization
          )?.[0]
        );
      }
      if (templateDetails?.external_model) {
        setRecommendationModelSelected(
          recommendationModels.filter(
            (o) => o.folder === templateDetails?.external_model
          )?.[0]
        );
      }
      if (templateDetails?.recommendation_template) {
        setReportTemplateSelected(
          reportTemplate.filter(
            (o) => o.id === templateDetails?.recommendation_template
          )?.[0]
        );
      }
      if (templateDetails?.primary_uid) {
        setOrganizationPrimarySelected(
          organizationPrimary?.[0]?.options?.filter(
            (o) => o.id === templateDetails?.primary_uid
          )?.[0]
        );
      }
      if (templateDetails?.detailed_info?.category_head) {
        setCategoryListSelected(
          categoryList.filter(
            (o) => o.id === templateDetails?.detailed_info?.category_head
          )?.[0]
        );
        if (templateDetails?.detailed_info?.category_option) {
          setCategoryOptionSelected(
            categoryOption.filter(
              (o) => o.id === templateDetails?.detailed_info?.category_option
            )?.[0]
          );
        }
      }
      if (templateDetails?.detailed_info?.test_output) {
        setTestOutput(templateDetails?.detailed_info?.test_output);
      }
      if (templateDetails?.receipient_list?.length > 0) {
        setEmployeeAddedList(reportTemplate.receipient_list);
      }
    }, 500);
    return () => clearTimeout(timer1);
  }, [
    templateDetails,
    organizationList,
    reportTemplate,
    recommendationModels,
    organizationPrimary,
    categoryList,
    categoryOption,
  ]);

  function AnySurveyWithPrimary(object) {
    setOrganizationPrimarySelected(object);
    setStatusInfo(9);
    setDebriefSurveySelected("");
    setEmployeeSelected("");
  }

  function SpecificSurvey(object) {
    setDebriefSurveySelected(object);
    if (object?.status < 5) {
      setStatusInfo(9);
    } else {
      setStatusInfo(1);
    }
    setEmployeeSelected("");
    setOrganizationPrimarySelected("");
    //
    setCategoryListSelected("");
    setCategoryOptionSelected("");
  }

  function BasedOneEmployee(object) {
    setEmployeeSelected(object);
    setStatusInfo(1);
    setDebriefSurveySelected("");
    setOrganizationPrimarySelected("");
    //
    setCategoryListSelected("");
    setCategoryOptionSelected("");
  }

  function FnCategoryListSelected(object) {
    setEmployeeSelected(false);
    setDebriefSurveySelected(false);
    setCategoryListSelected(object);
    setCategoryOptionSelected("");
    setStatusInfo(9);
  }
  function CategoryOptionSelected(object) {
    setCategoryOptionSelected(object);
    setEmployeeAddedList([]);
    setStatusInfo(9);
  }

  function addEmployeeToList(object) {
    setCategoryListSelected("");
    setCategoryOptionSelected("");
    setEmployeeAddedList(
      Array.from(new Set([...employeeAddedList, object.id]))
    );
    setStatusInfo(9);
  }

  useEffect(() => {
    if (
      !debriefSurveySelected?.id &&
      !employeeSelected?.id &&
      !categoryListSelected?.id
    ) {
      setStatusInfo(9);
    }
  }, [debriefSurveySelected, employeeSelected, categoryListSelected]);

  const onSubmit = () => {
    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }
    if (templateId) {
      dispatch({
        type: SG_EDIT_SCHEDULE_TEMPLATE,
        payload: {
          id: templateId,
          status: statusInfo, // 1 Run Now and 9 Schedule
          organization: organizationId,
          recommendation_template: reportTemplateSelected?.id,
          debrief_schedule: debriefSurveySelected?.id,
          external_model: recommendationModelSelected?.folder,
          primary_uid: organizationPrimarySelected?.id,
          detailed_info: {
            category_head: categoryListSelected?.id,
            category_option: categoryOptionSelected?.id,
            test_output: testOutput,
          },
          type_of: typeOfSelected?.id,
          recipient_list:
            statusInfo === 9 ? employeeAddedList : [employeeSelected?.id],
        },
      });
    } else {
      dispatch({
        type: SG_ADD_SCHEDULE_TEMPLATE,
        payload: {
          status: statusInfo, // 1 Run Now and 9 Schedule
          organization: organizationId,
          recommendation_template: reportTemplateSelected?.id,
          debrief_schedule: debriefSurveySelected?.id,
          external_model: recommendationModelSelected?.folder,
          primary_uid: organizationPrimarySelected?.id,
          detailed_info: {
            category_head: categoryListSelected?.id,
            category_option: categoryOptionSelected?.id,
            test_output: testOutput,
          },
          type_of: typeOfSelected?.id,
          recipient_list:
            statusInfo === 9 ? employeeAddedList : [employeeSelected?.id],
        },
      });
    }
    setPristine(true);
  };

  useEffect(() => {
    const ErrorList = [];
    let totalErrors = 0;
    if (!organizationId) {
      ErrorList.push("Please Select an Org");
      totalErrors += 1;
    }
    if (!reportTemplateSelected?.id) {
      ErrorList.push("Please select a report template");
      totalErrors += 1;
    }
    if (!recommendationModelSelected?.id) {
      ErrorList.push("Please select a model");
      totalErrors += 1;
    }
    if (!typeOfSelected?.value) {
      ErrorList.push("Please Select a Type");
      totalErrors += 1;
    }
    // if (
    //   !debriefSurveySelected?.id &&
    //   !employeeSelected?.id &&
    //   !categoryListSelected?.id &&
    //   !organizationPrimarySelected?.id
    // ) {
    //   ErrorList.push(
    //     "Please select a Previous Survey or Employee or Primary or Category"
    //   );
    //   totalErrors += 1;
    // }
    // if (statusInfo === 9) {
    //   if (!categoryListSelected?.id) {
    //     ErrorList.push("Please select a  or Employee");
    //     totalErrors += 1;
    //   }
    // }
    // ErrorList['errors'] = totalErrors
    setFormErrors({
      message: ErrorList,
      errors: totalErrors,
    });
  }, [
    pristine,
    reportTemplateSelected,
    recommendationModelSelected,
    debriefSurveySelected,
    employeeSelected,
    organizationPrimarySelected,
    categoryListSelected,
    employeeAddedList,
    organizationId,
    typeOfSelected,
    templateDetails,
  ]);

  return (
    <Container>
      <SectionDiv>
        <BasicErrorMessage />
      </SectionDiv>
      <SectionDiv>
        {formErrors?.errors && !pristine && (
          <Message error list={formErrors?.message} />
        )}
      </SectionDiv>
      <ColoredContainer top>
      <Row>
        <LeftColumn>
          <Label htmlFor="organization">Organization:</Label>
        </LeftColumn>
        <RightColumn>
          <Select
            name="organization"
            options={organizationList}
            // isClearable
            onChange={setOrganizationSelected}
            value={organizationSelected}
            getOptionLabel={(option) => `${option.id} # ${option.name}`}
            getOptionValue={(option) => option.id}
          />
        </RightColumn>
      </Row>
      <Row>
        <LeftColumn>
          <Label htmlFor="reportTemplate">Template:</Label>
        </LeftColumn>
        <RightColumn>
          <Select
            name="reportTemplate"
            options={reportTemplate}
            // isClearable
            onChange={setReportTemplateSelected}
            value={reportTemplateSelected}
            getOptionLabel={(option) => `${option.id} # ${option.name}`}
            getOptionValue={(option) => option.id}
          />
        </RightColumn>
      </Row>
      <Row>
        <LeftColumn>
          <Label htmlFor="model">Model:</Label>
        </LeftColumn>
        <RightColumn>
          <Select
            name="model"
            options={recommendationModels}
            // isClearable
            onChange={setRecommendationModelSelected}
            value={recommendationModelSelected}
            getOptionLabel={(option) => `${option.folder} # ${option.name}`}
            getOptionValue={(option) => option.folder}
          />
        </RightColumn>
      </Row>
      <Row>
        <LeftColumn>
          <Label htmlFor="model">Type:</Label>
        </LeftColumn>
        <RightColumn>
          <Select
            name="model"
            options={SCHEDULE_TEMPLATE_TYPE}
            // isClearable
            onChange={setTypeOfSelected}
            value={typeOfSelected}
            // getOptionLabel={(option) => option.label}
            // getOptionValue={(option) => option.value}
          />
        </RightColumn>
      </Row>
      <Row>
        <LeftColumn>Test Output:</LeftColumn>
        <RightColumn>
          <input
            type="checkbox"
            checked={testOutput}
            onChange={(e) => setTestOutput(e.target.checked)}
          />{" "}
          <span>The results would be used only for testing</span>
        </RightColumn>
      </Row>
      </ColoredContainer>
      {/* <ColoredContainer>
        {!templateId && (
          <Row>
            <LeftColumn>
              <Label htmlFor="debrief_schedule">Specific Survey:</Label>
            </LeftColumn>
            <RightColumn>
              <Select
                name="debrief_schedule"
                options={debriefSurveyList}
                isClearable
                onChange={(e) => SpecificSurvey(e)}
                value={debriefSurveySelected}
                getOptionLabel={(option) =>
                  `${option.id}-${option.name}-${
                    option?.status_text
                  }-#-${format(
                    new Date(option?.close_at),
                    "dd-MMM-yyyy hh:mm a"
                  )}`
                }
                getOptionValue={(option) => option.id}
              />
            </RightColumn>
          </Row>
        )}

        {!templateId && <OrSeperator TextValue={"OR"} />}
        {!templateId && (
          <Row>
            <LeftColumn>
              <Label htmlFor="primary_uid">
                Survey Based on Employee Permission:
              </Label>
            </LeftColumn>
            <RightColumn>
              <Select
                name="employeeList"
                options={employeeList}
                isClearable
                onChange={(e) => BasedOneEmployee(e)}
                getOptionLabel={(option) =>
                  `${option?.first_name} ${option?.last_name} - ${option?.email}`
                }
                getOptionValue={(option) => `${option?.id}`}
                value={employeeSelected}
              />
            </RightColumn>
          </Row>
        )}
      </ColoredContainer> */}

      <ColoredContainer>
        <Row>
          <LeftColumn>
            <Label htmlFor="primary_uid">Primary:</Label>
          </LeftColumn>
          <RightColumn>
            <Select
              name="primary_uid"
              options={organizationPrimary}
              isClearable
              onChange={(e) => AnySurveyWithPrimary(e)}
              value={organizationPrimarySelected}
              getOptionLabel={(option) => `${option.name} - ${option.id}`}
              getOptionValue={(option) => option.id}
            />
          </RightColumn>
        </Row>
        {!templateId && <OrSeperator TextValue={"Filter"} />}
        <Row>
          <LeftColumn>
            <Label htmlFor="category_name">Select Category:</Label>
          </LeftColumn>
          <RightColumn>
            <Select
              name="category_name"
              options={categoryList}
              isClearable
              onChange={FnCategoryListSelected}
              value={categoryListSelected}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder="Select all Options for selected Primary"
            />
          </RightColumn>
        </Row>
        {!templateId && <OrSeperator TextValue={"Filter"} />}
        <Row>
          <LeftColumn>
            <Label htmlFor="category_role">Select Role:</Label>
          </LeftColumn>
          <RightColumn>
            <Select
              name="category_role"
              options={categoryOption}
              isClearable
              onChange={(e) => CategoryOptionSelected(e)}
              value={categoryOptionSelected}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder="Select all Options"
            />
          </RightColumn>
        </Row>
        {/* <OrSeperator TextValue={"OR"} />
          <Row>
            <LeftColumn></LeftColumn>
            <RightColumn>{JSON.stringify(employeeAddedList)}</RightColumn>
          </Row>
          <Row>
            <LeftColumn>
              <Label htmlFor="receipient_list">Recipients:</Label>
            </LeftColumn>

            <RightColumn>
              <Select
                name="employeeListFiltered"
                options={employeeList}
                isClearable
                onChange={(e) => addEmployeeToList(e)}
                value={""}
                getOptionLabel={(option) =>
                  `${option?.first_name} ${option?.last_name} - ${option?.email}`
                }
                getOptionValue={(option) => `${option?.id}`}
              />
            </RightColumn>
          </Row> */}
      </ColoredContainer>

      <Row>
        <LeftColumn></LeftColumn>
        <RightAlignedColumn>
          {statusInfo === 1 ? (
            <Button onClick={onSubmit} color="orange">
              Run Right Away
            </Button>
          ) : (
            <Button onClick={onSubmit} color={templateId ? "violet" : "green"}>
              {templateId ? "Update Schedule Report" : "Create Report Schedule"}
            </Button>
          )}
        </RightAlignedColumn>
      </Row>
    </Container>
  );
};

export default DataForm;

function OrSeperator({ TextValue }) {
  return (
    <OrSeperatorContainer>
      <VerticalLine />
      <OrText>{TextValue}</OrText>
      <VerticalLine />
    </OrSeperatorContainer>
  );
}

const Container = styled.div`
  /* Add your custom styles here */
`;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;
const ColoredContainer = styled.div`
  /* Add your custom styles here */
  border: 1px solid ${(props) => (props.top === true ? "#1a33e5" : "#ff0000")};
  padding: 30px 10px;
  margin: 30px 0px;
`;

const LeftColumn = styled.div`
  flex: 1;
  text-align: left;
`;

const RightColumn = styled.div`
  flex: 4;
`;

const RightAlignedColumn = styled.div`
  justify-content: flex-end;
`;

const Label = styled.label`
  font-size: 16px;
`;

const OrSeperatorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const VerticalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000;
`;

const OrText = styled.p`
  margin: 0 10px;
  font-weight: bold;
`;

const StyledButton = styled.button`
  width: 100%;
  height: 40px;
  min-height: 55px;
  border-radius: 8px;
  background-color: #2d50e2;
  color: white;
  font-size: 18px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 30px;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;
