import styled, { keyframes } from 'styled-components';
import React from "react";
import {Modal,Button} from 'semantic-ui-react'



const Preview = ({open,setOpen,text}) =>{
    
    return (
        <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >

        <Container>
            {text}
        </Container>
      
    </Modal>
    )
}

export default Preview


const Container = styled.div`
    font-family:'Raleway';
    padding:40px;
    white-space: pre-wrap;
    line-height:1.5;
    font-size:14px;
`