import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import {
  RESET_SCHEDULE_TEMPLATE,
} from "constants/actions";

import { Card, Button, List } from "semantic-ui-react";

const RowCard = ({ row }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function ViewRow(row) {
    dispatch({
      type: RESET_SCHEDULE_TEMPLATE,
    });
    navigate(`/app/schedule-template-report/details/${row?.id}`)
  }

  function EditRow(row) {
    dispatch({
      type: RESET_SCHEDULE_TEMPLATE,
    });
    navigate(`/app/schedule-template-report/edit/${row?.id}`)
  }

  return (
    <>
      <Card fluid key={row?.id}>
        <Card.Content>
          <Card.Header>
            {row?.first_name} {row?.last_name}
          </Card.Header>
          <Card.Meta>
            {row?.email} {row?.mobiie}
          </Card.Meta>

          <Container>
            <List horizontal>

            <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>ID#</List.Header>
                    {row?.id}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Org ID#</List.Header>
                    {row?.organization}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>external_model</List.Header>
                    {row?.external_model}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>recommendation_template</List.Header>
                    {row?.recommendation_template}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>status_text</List.Header>
                    {row?.status_text}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Created</List.Header>
                    {row?.created_at &&
                      format(new Date(row?.created_at), "dd-MMM-yyyy")}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Last Updated</List.Header>
                    {row?.updated_at &&
                      format(new Date(row?.updated_at), "dd-MMM-yyyy")}
                  </ListContainer>
                </List.Content>
              </List.Item>
            </List>
          </Container>

          <Card.Description>
            {row?.status === 9 && (
              <Button
                floated="right"
                color="violet"
                onClick={() =>
                  EditRow(row)
                }
              >
                Edit
              </Button>
            )}
            
              <Button
                floated="right"
                color="green"
                onClick={() =>
                  ViewRow(row)
                }
              >
                More
              </Button>
            
          </Card.Description>
        </Card.Content>
      </Card>
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`;
