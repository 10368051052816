import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import components from './components.json'
import items from './items.json'
import TopProgress from './TopProgress'
import { Dropdown,Button,Input, Modal  } from 'semantic-ui-react';
import AddComponent from './Modal'
import { render } from '@testing-library/react';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    SG_FETCH_REPORT_TEMPLATE,
    SG_ADD_REPORT_TEMPLATE,
    SG_EDIT_REPORT_TEMPLATE,
    SG_DELETE_REPORT_TEMPLATE
} from "constants/actions";
import {useNavigate, useParams } from "react-router";
import { description_sample, template_versions } from "./dataUsed";
import toast, { Toaster } from 'react-hot-toast';

const URL = 'story-templates'
const StoryTemplate = (props) => {
    const { setNewResponse, newResponse } = props;
    const navigate = useNavigate();
    const [template, setTemplate] = useState([])
    const [reportTemplate, setReportTemplate] = useState(null);
    const [selected, setSelected] = useState(null)
    const [componentItem, setComponentItem] = useState(null)
    const [editing, setEditing] = useState(false)
    const[selectedStep, setSelectedStep] = useState(0)
    const dispatch = useDispatch();
    const { id } = useParams();
    const [templateId, setTemplateId] = useState(false);
    const [template_name, setTemplateName] = useState("");
    const [selectedVersion, setSelectedVersion] = useState(5);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [template_description, setTemplateDescription] =
      useState(description_sample);

    const [componentList,setComponentList] = useState(JSON.parse(JSON.stringify(components)).map((item)=>{
        return {value:item.componentId,text:item.title,data:item} 
    }))

    const { get_report_template,newTemplate,
        errors } = useSelector(
        (state) => ({
            get_report_template: state.report_template,
            newTemplate: state.report_template?.created,
            errors: state.errors,
        }),
        shallowEqual
        );

    useEffect(() => {
        if (newTemplate && !id && !templateId) { 
            setTemplateId(newTemplate.id);
            toast.success('Template created successfully');
        }
    }, [newTemplate]);

    useEffect(() => {
        if (id) {
            setTemplateId(id);
            dispatch({
                type: SG_FETCH_REPORT_TEMPLATE,
                payload: { id: id },
            });
            }
    }, [dispatch, templateId]);

    useEffect(() => {
        if (reportTemplate) {
            setTemplate(reportTemplate.template?.[0]?.template || reportTemplate.template)
            setTemplateName(reportTemplate.name)
            setTemplateDescription(reportTemplate.template?.[0]?.description)
        }
    }, [reportTemplate])

    useEffect(() => {
        if (templateId && get_report_template?.[templateId]) {
            setReportTemplate(get_report_template?.[templateId])
        }
    }, [templateId,get_report_template ])

    const add_component = () =>{
        let new_template = JSON.parse(JSON.stringify(template));
        new_template.push(components.find(f=>f.componentId==selected))
        setTemplate(new_template)
    }

    const deleteConfirm = () => {
        setShowDeleteConfirm(true);
    }

    const deleteReportTemplate = () => {
        dispatch({
          type: SG_DELETE_REPORT_TEMPLATE,
          payload: {
            id: templateId,
          },
        });

        setNewResponse(Math.random());

        // Adding a buffer here so the back-end has time to delete the template
        setTimeout(() => {
            navigate(`/app/${URL}`);
        }, 300);
    }

    const editData = (e,idx) =>{
        let new_template = JSON.parse(JSON.stringify(template));
        new_template[selectedStep].manualInputs[idx].value = e.target.value
        setTemplate(new_template)
    }

    const deleteStep = (idx) =>{
        let new_template = [...template]
        new_template.splice(idx,1)
        setTemplate(new_template)
        if (selectedStep > 0) {
            setSelectedStep(selectedStep - 1);
        } else {
            setSelectedStep(0);
        }
    }

    const MoveUp = (idx) =>{
        if(idx!==0){
            let new_template = [...template]
            let temp = new_template[idx]
            new_template[idx] = new_template[idx-1]
            new_template[idx-1] = temp
            setTemplate(new_template)
            setSelectedStep(idx-1)
        }
    }


    const MoveDown = (idx) =>{
        if(idx==template.length-1){
            return
        }

        let new_template = [...template]
        let temp = new_template[idx]
        new_template[idx] = new_template[idx+1]
        new_template[idx+1] = temp
        setTemplate(new_template)

        setSelectedStep(idx+1)
    }

    const saveStory = () => {
        if (templateId) {
            // SG_EDIT_REPORT_TEMPLATE
            dispatch({
                type: SG_EDIT_REPORT_TEMPLATE,
                payload: {
                id: templateId,
                name: template_name,
                // api_version: selectedVersion,
                status: 9,
                type_of: 2,
                template: [{
                    description: template_description,
                    template: template
                }],
                },
            });
            toast.success('Template updated successfully');
        } else {
            dispatch({
                type: SG_ADD_REPORT_TEMPLATE,
                payload: {
                    name: template_name,
                    // api_version: selectedVersion,
                    type_of: 2,
                    status: 9,
                    template: [{
                        description: template_description,
                        template: template
                    }],
                },
            });
        }
    }

    const deleteTemplateConfirm = (
        <Modal
          onClose={() => setShowDeleteConfirm(false)}
          onOpen={() => setShowDeleteConfirm(true)}
          open={showDeleteConfirm}
          size="mini"
          closeOnDimmerClick
        >
          <Modal.Content>
            <Modal.Description>
              <Header>Delete Template?</Header>
              <p>Are you sure you want to delete this template?</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <ActionWrapper>
              <CancelText color="black" onClick={() => setShowDeleteConfirm(false)}>
                Cancel
              </CancelText>
              <DeleteConfirm onClick={() => deleteReportTemplate()}>
                Delete
              </DeleteConfirm>
            </ActionWrapper>
          </Modal.Actions>
        </Modal>
      );

    const renderComponent = () =>{
        return template?.[selectedStep]?.manualInputs?.map((input, idx) => {
                        if(input.component=='dropdown'){
                            return (
                                <div style={{marginTop:20}}  id={idx}>
                                    <Label  id={idx}>{input.name}</Label>
                                    <Dropdown 
                                     id={idx}
                                    placeholder={input.placeholder}
                                    fluid selection 
                                    options={input.options}
                                    value={input.value?input.value:''}
                                    onChange={(e,{value})=>{
                                        editData({target:{value}},idx)
                                    }}
                                    />
                                </div>
                            )
                        }


                        return (
                            <div style={{marginTop:20}}>
                                <Label>{input.name}</Label>
                                {input.component=='textarea'?
                                <textarea 
                                value={input.value?input.value:''}
                                onChange={(e)=>editData(e,idx)}
                                id={idx}
                                placeholder={input.placeholder}
                                style={{width:'100%',height:100}}/>:
                                <Input 
                                value={input.value?input.value:''}
                                id={idx}
                                onChange={(e)=>editData(e,idx)}
                                placeholder={input.placeholder} />}

                            </div>
                        )
                    })}


    return (
        <Container>
            <Toaster />
            <Half>
        <Title2>Build a data story template</Title2>

        <InputArea>
          <Label>Template name</Label>
          <Input
            placeholder="Enter a title"
            value={template_name}
            onChange={(e) => setTemplateName(e.target.value)}
          />
                </InputArea>
          <Label>Template Component</Label>
            <Dropdown 
                placeholder='Select a component'
                fluid selection 
                options={[...componentList]}
                value={selected}
                style={{marginBottom:20}}
                onChange={(e,{value})=>{
                    setSelected(value)
                } }
                 />
            <Button onClick={()=>add_component()}>
                Add to Story
            </Button>
             <TopProgress step={selectedStep} steps={template} onClick={(value)=>setSelectedStep(value)}/>

            <Button onClick={() => saveStory()}>
                Save
                </Button>
                {templateId && (
                    <Button onClick={() => deleteConfirm()}>
                        Delete Template
                    </Button>
                )}
            </Half>

            <Half2>


            {template.length==0?
                <Empty>Please add a component</Empty>:
                <>
                    <TopContainer>
                    <Title>Edit Component</Title>
                    <Button onClick={()=>MoveUp(selectedStep)}>Move up</Button>
                    <Button onClick={()=>MoveDown(selectedStep)}>Move down</Button>
                    <Button onClick={()=>deleteStep(selectedStep)}>Delete</Button>
                    </TopContainer>
                   {renderComponent()}
                </>
            }



            </Half2>

            {showDeleteConfirm && deleteTemplateConfirm}

        </Container>
    )
}


export default StoryTemplate

const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    padding:40px;
    font-family:'Raleway';
`

const Title = styled.div`
    font-size:20px;
    font-weight:600;
    
`

const Title2 = styled.div`
    font-size:20px;
    font-weight:600;
    margin-bottom:20px;
`

const Half = styled.div`
    width:30%;
    padding:0px 20px;
`

const Half2 = styled.div`
    width:70%;
    padding:0px 20px;
`

const Label = styled.div`
    margin-bottom:5px;
`

const TopContainer = styled.div`
    display:flex;
    width:100%;
    justify-content:space-between;
    align-items:center;
    margin-bottom:40px;
`

const Empty = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:16px;
    color:#2A3039;
    margin-top:20px;
    margin-bottom:20px;
`

const InputArea = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Header = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  color: black;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

const CancelText = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  padding-right: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #666d79;
  cursor: pointer;
`;

const DeleteConfirm = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  padding-right: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #2d70e2;
  cursor: pointer;
`;