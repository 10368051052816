import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
// import SearchBar from "./SearchBar";
import RowCard from "./RowCard";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  SG_GET_REPORT_TEMPLATE,
  UPDATE_REPORT_TEMPLATE_PAGE_NUMBER,
} from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from "semantic-ui-react";

const List = ({ reload, newResponse }) => {
  // employee_record_value
  const dispatch = useDispatch();

  //   const [resetPageLoad, setResetPageLoad] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    // if the response is ok 200
      if (results?.length === 0) {
          dispatch({
            type: SG_GET_REPORT_TEMPLATE,
            payload: `page=${page}&type_of=2${searchTerm ? `&search=${searchTerm}` : ""}`,
          });
      }
    setLoading(true);
  }, [dispatch, page, searchTerm, newResponse]);


  const { get_report_template } = useSelector(
    (state) => ({
      get_report_template: state.report_template,
    }),
    shallowEqual
  );

    useEffect(() => {
        if (results?.length > 0 && loading) {
            setLoading(false);
        }
    }, [results, loading]);



  useEffect(() => {
    setResults(get_report_template?.report_template?.results);
    setPage(get_report_template?.pageNumber);
    setTotalPages(
      Math.ceil(
        get_report_template?.report_template?.count /
          get_report_template?.report_template?.page_size
      )
    );
  }, [get_report_template, page]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_REPORT_TEMPLATE_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoading(get_report_template?.pending);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [get_report_template, newResponse]);


  return (
    <Container>
      {/* <SectionDiv>
                <SearchBar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm} /> 
            </SectionDiv>
  */}

      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>{results && <RowCard rows={results} />}</Card.Group>
      </Segment>
      <SectionDiv>
        <Pagination
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          activePage={page}
          totalPages={totalPages || 0}
          onPageChange={handlePageChange}
        />
      </SectionDiv>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;