import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import PlanFormat from './PlanFormat'
import { Accordion, Icon,Button,Loader } from 'semantic-ui-react'
import Slider from '../RecommendationEval/Slider'
import {sample } from "./dataUsed"

const Score = () =>{
    const [response,setResponse] = useState(0)
    const [reset,setReset] = useState(false)
    const [editedData, setEditedData] = useState(sample);


    const AskQuestion = () =>{
        // Logic here to save response for the current recommendation
        // Response should contain a link to the recommendation information
        // It can store all the recommendation information here, or just a link to the recommendation
        console.log(response)
    }

    const handleSaveTemplate = () => {
        // Replace the original dataArray with the editedData
        setDataArray([...editedData]);


        
        // Optionally, you can also send the editedData to your server or perform any other necessary actions.
      };

    return (
        <Container>
            <Center>
                <PlanFormat 
                    dataArray={sample} 
                    editedData={editedData} 
                    setEditedData={setEditedData} 
                    handleSaveTemplate={handleSaveTemplate}
                />

                <QuestionArea>
                    <Question>
                        Please rate the quality of recommendation in relation to the identified need.
                    </Question>
                    <Slider 
                        saveUpdatedAnswers={(v)=>setResponse(v)} 
                        reset={reset} 
                        setReset={setReset}
                    />

                    <div style={{width:'100%',display:'flex',justifyContent:'flex-end',marginTop:20}}>
                        <Button onClick={()=>AskQuestion()}>Submit</Button>
                    </div>
                </QuestionArea>

            </Center>
        </Container>
    )
}

export default Score

const Container = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:40px;
`

const Center = styled.div`
    max-width:1200px;
    font-family:'Raleway';
    display:flex;
`

const QuestionArea = styled.div`
    width:40%;
    display:flex;
    flex-direction:column;
    margin-top:30px;
    padding:20px;
`

const Question = styled.div`
    font-weight:bold;
`
