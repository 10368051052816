import React, { useEffect, useState } from "react";
// import styled from "styled-components";

import AuthDetails from "./AuthDetails";
import Details from "./Details";
import ExtraDetails from "./ExtraDetails";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_EMPLOYEE_PERMISSIONS } from "constants/actions";
//

const EmployeePermission = ({ employeeId }) => {
  const dispatch = useDispatch();

  const [authDetails, setAuthDetails] = useState([]);
  const [details, setDetails] = useState([]);
  const [extraDetails, setExtraDetails] = useState([]);
  useEffect(() => {
    if (employeeId) {
      dispatch({
        type: SG_GET_EMPLOYEE_PERMISSIONS,
        payload: `employee=${employeeId}`,
      });
    }
  }, [dispatch, employeeId]);

  const { get_employee_permission } = useSelector(
    (state) => ({
      get_employee_permission: state.employee_permission.employee_permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    setAuthDetails(get_employee_permission?.[0]?.auth_details);

    setDetails(get_employee_permission?.[0]?.details);
    if (get_employee_permission?.[0]?.extra_details) {
      const extra_details = Object.entries(
        get_employee_permission?.[0]?.extra_details
      );
      setExtraDetails(extra_details);
    }
  }, [get_employee_permission]);

  return (
    <>
      <AuthDetails header={"Auth Details"} rows={authDetails} />
      <Details header={"Details"} rows={details} />
      <ExtraDetails header={"Extra Details"} rows={extraDetails} />
    </>
  );
};

export default EmployeePermission;
