import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
import SearchBar from "./SearchBar";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  LOAD_EMPLOYEES,
  UPDATE_EMPLOYEE_PAGE_NUMBER,
  SG_GET_EMPLOYE_CATEGORY_LIST,
} from "constants/actions";

import {
  Dimmer,
  Loader,
  Card,
  Pagination,
  Segment,
  Message,
  Icon,
} from "semantic-ui-react";

import OrganizationSelect from "organizations/OrganizationSelect";
import EmployeeSummary from "./Summary";
import CategoryList from "./CategoryList";

const List = ({ hideOrgSelect, orgId }) => {
  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState(false);
  const [resetPageLoad, setResetPageLoad] = useState(false);

  const [results, setResults] = useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const [categoryNames, setCategoryNames] = useState([]);
  const [categoryValues, setCategoryValues] = useState([]);
  const [categorySearch, setCategorySearch] = useState(false);
  const [showCategoryList, setShowCategoryList] = useState(false);

  useEffect(() => {
    if (orgId) {
      setOrganizationId(orgId);
    }
  }, [orgId]);

  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    // if the response is ok 200
    dispatch({
      type: LOAD_EMPLOYEES,
      payload: `page=${page}${
        organizationId ? `&organization=${organizationId}` : ""
      }${searchTerm ? `&search=${searchTerm}` : ""}${
        categorySearch?.id ? `&category=${categorySearch?.id}` : ""
      }`,
    });

    dispatch({
      type: SG_GET_EMPLOYE_CATEGORY_LIST,
      payload: `show=true${
          organizationId ? `&organization=${organizationId}` : ""
        }${
          categorySearch?.id ? `&search=${categorySearch?.id}` : ""
        }`,
    });
    
    setLoading(true);
  }, [
    dispatch,
    page,
    searchTerm,
    organizationId,
    resetPageLoad,
    categorySearch,
  ]);

  const { get_employees, get_selectedOrg } = useSelector(
    (state) => ({
      get_employees: state.employees,
      get_selectedOrg: state.selectedOrg,
    }),
    shallowEqual
  );

  // console.log(get_employees?.employee_category_list?.response)

  useEffect(() => {
    setOrganizationId(get_selectedOrg?.organization?.id);
    // setResetPageLoad(!resetPageLoad)
  }, [get_selectedOrg]);

  useEffect(() => {
    setResults(get_employees?.employees?.results);
    setPage(get_employees?.pageNumber);
    setTotalPages(
      Math.floor(
        get_employees?.employees?.count / get_employees?.employees?.page_size
      )
    );
    if (get_employees?.employees?.count % get_employees?.employees?.page_size) {
      setTotalPages(
        Math.floor(
          get_employees?.employees?.count / get_employees?.employees?.page_size
        ) + 1
      );
    }
    setCategoryNames(get_employees?.employee_category_list?.response?.name);
    setCategoryValues(get_employees?.employee_category_list?.response?.value);
  }, [get_employees, page]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_EMPLOYEE_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoading(get_employees?.pending);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [get_employees]);

  function ResetCategoryList() {
    setPage(1)
    // setShowCategoryList(!showCategoryList);
    setCategorySearch(false);
  }

  function ShowingCategoryList() {
    setShowCategoryList(!showCategoryList);
  }

  return (
    <Container>
      <p>Total Employees [{get_employees?.employees?.count}]</p>
      <SectionDiv>
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </SectionDiv>
      {!hideOrgSelect && (
        <OrganizationSelect setResetPageLoad={setResetPageLoad} />
      )}

      {get_selectedOrg?.organization?.id && (
        <EmployeeSummary get_employees={get_employees} />
      )}

      <RightAlign>
      {categorySearch?.id   &&
      <ContainerFieldSelectable onClick={ResetCategoryList}>
              
                  Reset Category {categorySearch?.name} <Icon name="close" />
              
            </ContainerFieldSelectable>
      }
        <ContainerFieldSelectable onClick={ShowingCategoryList}>
          {showCategoryList ? (
            <>
              Hide Category List <Icon name="close" />
            </>
          ) : (
            "Show Category List"
          )}
        </ContainerFieldSelectable>
      </RightAlign>
           
      {showCategoryList && (
        <Container>
            <CategoryList
              names={categoryNames}
              values={categoryValues}
              setCategorySearch={setCategorySearch}
              valueIdName={get_employees?.employee_category_list?.response?.value_to_uid_list}
              nameIdName={get_employees?.employee_category_list?.response?.name_to_uid_list}
            />
        </Container>
      )}
      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>
          {results &&
            results.map((result) => <RowCard row={result} key={result?.id} />)}
        </Card.Group>
      </Segment>
      <SectionDiv>
        <Pagination
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          activePage={page || 1}
          totalPages={totalPages || 1}
          onPageChange={handlePageChange}
        />
      </SectionDiv>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;
