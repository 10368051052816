import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    SG_GET_RANDOM_GPT_RECOMMENDATION,
  SG_GET_REPORT_TEMPLATE_SELECT,
  SG_GET_RECOMMENDATION_MODELS,
  SG_ADD_RECOMMENDATION_MODEL_RATING
} from "constants/actions";

import Select from "react-select";

const Start = () => {
  const dispatch = useDispatch();
  const [reportTemplate, setReportTemplate] = useState([]);
  const [recommendationModels, setRecommendationModels] = useState([]);
  const [randomRecommendation, setRandomRecommendation] = useState([]);

  const { get_recommendation_models, get_report_template, get_gpt_recommendation } =
    useSelector(
      (state) => ({
        get_recommendation_models: state.recommendation_models,
        get_report_template: state.report_template,
        get_gpt_recommendation: state.gpt_recommendation,
      }),
      shallowEqual
    );

  useEffect(() => {
    dispatch({
      type: SG_GET_RECOMMENDATION_MODELS,
    });
    dispatch({
      type: SG_GET_REPORT_TEMPLATE_SELECT,
    });
  }, []);

  useEffect(() => {
    setRecommendationModels(get_recommendation_models?.recommendation_models?.response);
  }, [get_recommendation_models]);

  useEffect(() => {
    setReportTemplate(get_report_template?.select);
  }, [get_report_template]);

  useEffect(() => {
    setRandomRecommendation(get_gpt_recommendation?.random_recommendation);
  }, [get_gpt_recommendation]);

//   sample code
  function AddRating() {
    dispatch({
      type: SG_ADD_RECOMMENDATION_MODEL_RATING,
      payload: {
        chat_gpt_recommendation: 1,
        rating: {"rating": 1, "rating_type": "string"},
        external_model: "string"
      }
    });
  }

  //   sample code
  function GenerateRandom() {
    dispatch({
      type: SG_GET_RANDOM_GPT_RECOMMENDATION,
      payload: {
        chat_gpt_recommendation: 1,
        external_model: "string"
      }
    });
  }


  console.log(recommendationModels);
  console.log(reportTemplate);
  console.log(randomRecommendation);
  return (
    <Container>
      <Row>
        <Column>
        <Title>Model </Title>
          <label>Select Model</label>
          <Select
            placeholder={`Select Model`}
            // onChange={(e) => ChangeSelectedValue(variable, e)}
            // value={SetValue}
            options={recommendationModels}
            // isLoading={isLoading === variable ? isLoading : false}
            getOptionLabel={(recommendationModels) =>
              recommendationModels?.folder
            }
            getOptionValue={(recommendationModels) =>
              recommendationModels?.folder
            }
          />
          <p>this models are a list of folder which have description.json in them </p>
        </Column>
        <Column>
        <Title>Report Template</Title>
        <label>Select Template</label>
          <Select
            placeholder={`Select Template`}
            // onChange={(e) => ChangeSelectedValue(variable, e)}
            // value={SetValue}
            options={reportTemplate}
            // isLoading={isLoading === variable ? isLoading : false}
            getOptionLabel={(reportTemplate) =>
              reportTemplate?.name
            }
            getOptionValue={(reportTemplate) =>
              reportTemplate?.id
            }
          />
          <p>these are list of templates</p>
          </Column>
      </Row>
    <Column>
    <Title>Placeholder to show a random recommendation that matches the above criteria</Title>
    <p>You can choose to sent both of these or just one of them</p>
    <pre>{JSON.stringify({"chat_gpt_recommendation": 1,
        "external_model": "string"  })}
    </pre>
    <button onClick={GenerateRandom}>Generate Random</button>
    <p>this can be from the api once the above information is pushed out</p>
    <p>this api work is pending because there is nothing that matches the above criteria, this is brand new</p>

    </Column>

      <Column>
      <Title>Placeholder for controls like slider etc to evaluate the above response</Title>
      <button>Evaluate Above Response -1</button>
      <button>Evaluate Above Response -2</button>
      <button>Evaluate Above Response -3</button>
      </Column>
    </Container>
  );
};

export default Start;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex: 1;
  padding: 10px;
  border: 1px solid black; /* Add border style here */
`;

const Title = styled.p`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
`;

