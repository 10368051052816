import {
  UPDATE_SURVEY_STANDARD_PAGE_NUMBER,
  GET_SURVEY_STANDARD_PENDING,
  GET_SURVEY_STANDARDS,
  DELETE_SURVEY_STANDARD,
  FETCH_SURVEY_STANDARD,
  EDIT_SURVEY_STANDARD,
  ADD_SURVEY_STANDARD,
  CLEAR_SURVEY_STANDARD,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  survey_standard: [],
  deleted: null,
  updated: null,
  created: null,
  pending: false,
  pageNumber: 1,
};

export default function SurveyCompliance(state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY_STANDARD_PENDING:
      return {
        ...state,
        deleted: null,
        updated: null,
        created: null,
        pending: true,
      };
    case GET_SURVEY_STANDARDS:
      return {
        ...state,
        survey_standard: action.payload,
        pending: false,
      };
    case UPDATE_SURVEY_STANDARD_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case FETCH_SURVEY_STANDARD:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_SURVEY_STANDARD:
     
      return { ...state, updated: action.payload };

    case DELETE_SURVEY_STANDARD:
      return {
        ...state,
        deleted: action.payload,
      };

    case ADD_SURVEY_STANDARD:
      return {
        ...state,
        created: action.payload,
        survey_standard: [...state.survey_standard, action.payload],
      };

    case CLEAR_SURVEY_STANDARD:
      return {
        ...state,
        survey_standard: [],
        deleted: null,
        updated: null,
        created: null,
        pending: false,
        pageNumber: 1,
      };

    case CLEAR_ALL:
      return {
        ...state,
        survey_standard: [],
        deleted: null,
        updated: null,
        created: null,
        pending: false,
        pageNumber: 1,
      };

    default:
      return state;
  }
}
