import React from 'react';
import styled, { keyframes } from 'styled-components';
import {Button} from 'semantic-ui-react';
import ControlledInput from './ControlledInput';

const BasicInput = ({type,saved_value,onChange,deleteTemplate,moveTemplateUp,moveTemplateDown,placeholder}) =>{
    
    const [value,setValue] = React.useState('')

    React.useEffect(()=>{

        if(!saved_value){
            setValue('')
        }else{
            setValue(saved_value)
        }
       
    },[saved_value])

    
    return (
            <Container>
                <Label>{type} </Label>
                <ControlledInput 
                placeholder={placeholder}
                value={value}
                style={{width:'100%'}}
                onChange={(e)=>onChange(e.target.value)} />
                <Options>
                <Button style={{marginRight:10}} onClick={()=>moveTemplateUp()}>Up</Button>
                <Button style={{marginRight:10}} onClick={()=>moveTemplateDown()}>Down</Button>
                <Button size="mini" color="red" onClick={()=>deleteTemplate()}>Delete</Button>
                </Options>
            </Container>
    )
}

export default BasicInput;


const Container = styled.div`
    margin-bottom:20px;
    font-family:'Raleway';
    width:500px;
`

const Label = styled.div`

`

const Options = styled.div`
    display:flex;
`