import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
GET_EMPLOYEES,
LOAD_EMPLOYEES,
GET_EMPLOYE_CATEGORY_LIST,
SG_GET_EMPLOYE_CATEGORY_LIST,
GET_EMPLOYEE_SELECT_LIST,
SG_GET_EMPLOYEE_SELECT_LIST,
SEARCH_EMPLOYEES,
SG_SEARCH_EMPLOYEES, 
DELETE_EMPLOYEE,
RESET_EMPLOYEE,
PENDING_EMPLOYEE,
REMOVE_EMPLOYEE,
PULL_EMPLOYEE,
FETCH_EMPLOYEE,
SG_FETCH_USER_EMPLOYEE,
FETCH_USER_EMPLOYEE,
UPDATE_EMPLOYEE,
EDIT_EMPLOYEE,
UPDATE_EMPLOYEE_PIC,
EDIT_EMPLOYEE_PIC,
SAVE_EMPLOYEE,
ADD_EMPLOYEE,
REGISTER_EMPLOYEE,
// CLEAR_EMPLOYEE,
VALIDATE_EMPLOYEE,
VALIDATE_EMAIL,
SG_VALIDATE_EMPLOYEE,
SIGNUP_TOKEN,
SG_SIGNUP_TOKEN,
// CLEAR_VALIDATE_EMPLOYEE,
  ALL_ERRORS,
} from "constants/actions";


function* loademployees(action) {
  try {
    const json = yield call(api.GET_EMPLOYEES, action.payload);

    yield put({ type: GET_EMPLOYEES, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeLoad() {
  yield takeLatest(LOAD_EMPLOYEES, loademployees);
}
//

function* loademployeecategories(action) {
  try {
    const json = yield call(api.GET_EMPLOYEE_CATEGORY_LIST, action.payload);

    yield put({ type: GET_EMPLOYE_CATEGORY_LIST, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeCategoryLoad() {
  yield takeLatest(SG_GET_EMPLOYE_CATEGORY_LIST, loademployeecategories);
}
//

function* loademployeeselect(action) {
  try {
    const json = yield call(api.GET_EMPLOYEE_SELECT_LIST, action.payload);

    yield put({ type: GET_EMPLOYEE_SELECT_LIST, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeSelectLoad() {
  yield takeLatest(SG_GET_EMPLOYEE_SELECT_LIST, loademployeeselect);
}
//

function* validateEmployee(action) {
  try {
    const json = yield call(api.VALIDATE_EMPLOYEE, action.payload);
    yield put({ type: VALIDATE_EMPLOYEE, payload: json.data });
    yield put({ type: VALIDATE_EMAIL, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeValidate() {
  yield takeLatest(SG_VALIDATE_EMPLOYEE, validateEmployee);
}

function* searchEmployees(action) {
  try {
    const json = yield call(api.GET_EMPLOYEES, action.payload);
    yield put({ type: SEARCH_EMPLOYEES, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeSearch() {
  yield takeLatest(SG_SEARCH_EMPLOYEES, searchEmployees);
}

function* signupToken(action) {
  try {
    const json = yield call(api.SIGNUP_TOKEN, action.payload);
    yield put({ type: SIGNUP_TOKEN, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeSignUpToken() {
  yield takeLatest(SG_SIGNUP_TOKEN, signupToken);
}

function* fetchemployees(action) {
  try {
    const json = yield call(api.FETCH_EMPLOYEE, action.payload);
    yield put({ type: FETCH_EMPLOYEE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeFetch() {
  yield takeLatest(PULL_EMPLOYEE, fetchemployees);
}

function* fetchUsremployees(action) {
  yield put({ type: PENDING_EMPLOYEE });
  try {
    const json = yield call(api.FETCH_EMPLOYEE, action.payload);
    yield put({ type: FETCH_USER_EMPLOYEE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeUsrFetch() {
  yield takeLatest(SG_FETCH_USER_EMPLOYEE, fetchUsremployees);
}

function* updateemployees(action) {
  try {
    const json = yield call(api.EDIT_EMPLOYEE, action.payload);
    yield put({ type: EDIT_EMPLOYEE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeUpdate() {
  yield takeLatest(UPDATE_EMPLOYEE, updateemployees);
}

function* updateemployeepic(action) {

  try {
    const json = yield call(api.ADD_EMPLOYEE_PIC, action.payload);
    yield put({ type: EDIT_EMPLOYEE_PIC, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeePicUpdate() {
  yield takeLatest(UPDATE_EMPLOYEE_PIC, updateemployeepic);
}

function* addemployees(action) {
  yield put({ type: RESET_EMPLOYEE });
  
  try {
    const json = yield call(api.ADD_EMPLOYEE, action.payload);
    yield put({ type: ADD_EMPLOYEE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeAdd() {
  yield takeLatest(SAVE_EMPLOYEE, addemployees);
}

function* registeremployees(action) {
  try {
    const json = yield call(api.REGISTER_EMPLOYEE, action.payload);
    yield put({ type: ADD_EMPLOYEE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeRegister() {
  yield takeLatest(REGISTER_EMPLOYEE, registeremployees);
}

function* deleteemployees(action) {
  try {
    yield call(api.DELETE_EMPLOYEE, action.payload);
    yield put({ type: DELETE_EMPLOYEE, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeDelete() {
  yield takeLatest(REMOVE_EMPLOYEE, deleteemployees);
}


export default function* index() {
  yield all([
    employeeLoad(),
    employeeCategoryLoad(),
    employeeSelectLoad(),
    employeeSearch(),
    employeeSignUpToken(),
    employeeFetch(),
    employeeUsrFetch(),
    employeeUpdate(),
    employeePicUpdate(),
    employeeAdd(),
    employeeRegister(),
    employeeDelete(),
    employeeValidate(),
  ]);
}
