import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Header } from "semantic-ui-react";

const CategoryTags = ({ names, values, setCategorySearch, valueIdName, nameIdName }) => {
  // console.log(valueIdName)
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column floated="left" width={7}>
          <Header as="h2">
            Name List
            <Header.Subheader>List of All Category Names</Header.Subheader>
          </Header>
          <ContainerBox>
            {names &&
              names.map((n, i) => {
                return (
                  <ContainerField key={i}>
                    {nameIdName?.[n?.name]} <NumberDisplay>({n?.no_of})</NumberDisplay>
                  </ContainerField>
                );
              })}
          </ContainerBox>
        </Grid.Column>

        <Grid.Column floated="right" width={8}>
          <Header as="h2">
            Values List
            <Header.Subheader>List of Values</Header.Subheader>
          </Header>
          <ContainerBox>
            {values &&
              values.map((n, i) => {
                return (
                  <ContainerFieldSelectable key={i} onClick={() => setCategorySearch({id: n?.name, name: valueIdName?.[n?.name]})}>
                     {valueIdName?.[n?.name]} <NumberDisplay>({n?.no_of})</NumberDisplay>
                  </ContainerFieldSelectable>
                );
              })}
          </ContainerBox>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

CategoryTags.propTypes = {
  names: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
};

export default CategoryTags;

const ContainerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const ContainerField = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;

const NumberDisplay = styled.span`
    padding-left: 5px;
    font-weight: 600;
`