import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Button, Input, Message } from 'semantic-ui-react'
import { useNavigate } from 'react-router';
import Select from "react-select";

import { CATEGORY_COMPLIANCE_HEADERS } from "data/AdminData"

import { useDispatch } from "react-redux";
import {
    SG_ADD_SURVEY_STRUCTURE_COMPLIANCE, SG_EDIT_SURVEY_STRUCTURE_COMPLIANCE,
} from "constants/actions";

import { AddRow } from './AddEditRow';
import TableList from './TableList';

const Form = ({ row, detailLevel }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [changed, setChanged] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [selectedRow, setSelectedRow] = useState(false)

    const [selectedRowKey, setSelectedRowKey] = useState(false)
    const [dataRows, setDataRows] = useState([])

    const [surveyTypeSelected, setSurveyTypeSelected] = useState([])

    const [name, setName] = useState('')

    useEffect(() => {
        setSurveyTypeSelected(CATEGORY_COMPLIANCE_HEADERS.filter(cc => cc.value === row?.priority)?.[0])
        setName(row?.name)
    }, [row]);

console.log(surveyTypeSelected)

    function UpdateRow() {
        if (row?.id) {
            dispatch({
                type: SG_EDIT_SURVEY_STRUCTURE_COMPLIANCE,
                payload: {
                    id: row?.id,
                    priority: surveyTypeSelected?.value,
                    name: name,
                },
            });
        } else {
            dispatch({
                type: SG_ADD_SURVEY_STRUCTURE_COMPLIANCE,
                payload: {
                    priority: surveyTypeSelected?.value,
                    name: name,
                    categories: [],
                    detail_level: detailLevel || 1,
                },
            });
            if (detailLevel && detailLevel === 2) {
                navigate("/app/category-questions/")
            }
            navigate("/app/category-compliance/")
        }
    }

    useEffect(() => {
        setDataRows(row?.categories)
    }, [row]);

    function UpdateAllRows() {
        if (row?.id) {
            dispatch({
                type: SG_EDIT_SURVEY_STRUCTURE_COMPLIANCE,
                payload: {
                    id: row?.id,
                    priority: surveyTypeSelected?.id,
                    name: name,
                    categories: dataRows
                },
            });
            setShowMessage({
                header: "Updated Rows",
                content: "Successfully updated Rows"
            })
        }
    }

    function AddToRow(row) {
        console.log(row)
        const _ListRows = dataRows
        _ListRows.push(row)
        setDataRows(_ListRows)
    }

    function EditSelectRow(i, row) {
        console.log(i, row)
        setSelectedRowKey(i)
        setSelectedRow(row)
    }

    function EditUpdateRow(row) {
        const _ListRows = dataRows
        _ListRows[selectedRowKey] = row
        setSelectedRow(_ListRows)
        setSelectedRowKey(false)
    }

    function deleteRow(id) {
        const _ListRows = dataRows.filter(sr => sr.id !== id)
        setDataRows(_ListRows)
        setChanged(Math.random())
    }

    return (
        <>
        {showMessage &&
         <Message
         positive
          onDismiss={() => setShowMessage(false)}
          header={showMessage?.header}
          content={showMessage?.content}
        />
        }
        
            <SectionDiv>
                <SectionHeader>
                    <Select
                        name="survey_preference"
                        options={CATEGORY_COMPLIANCE_HEADERS}
                        // isClearable
                        onChange={setSurveyTypeSelected}
                        // getOptionLabel={(option) => `${option.name}`}
                        // getOptionValue={(option) => `${option.id}`}
                        value={surveyTypeSelected}
                    />
                </SectionHeader>

                <RowInputLine>
                    <Input fluid
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </RowInputLine>
                <Button onClick={UpdateRow}>{row?.id ? "Update Header" : "Add Header"}</Button>
            </SectionDiv>
            <SectionDiv>
                {dataRows &&<TableList 
                    rows={dataRows}
                    deleteRow={deleteRow}
                    EditSelectRow={EditSelectRow}
                /> }
                
            </SectionDiv>
            {row?.id && 
                <AddRow
                    AddToRow={AddToRow}
                    setChanged={setChanged}
                    selectedRow={selectedRow}
                    EditUpdateRow={EditUpdateRow}
                />
            }
            {row?.id &&<Button size='large' color="violet" floated='right' 
            onClick={UpdateAllRows}>Update</Button>}
        </>
    );
};

export default Form;

const SectionDiv = styled.div`
  padding: 5px;
  margin: 5px;
`

const SectionHeader = styled.span`
  min-width: 300px;
  font-size: 110%;
  margin: 0 10px;
  display: inline-block;
`

const RowInputLine = styled.span`
  min-width: 500px;
  font-size: 110%;
  margin: 10px;
  display: inline-block;
`
const RowLine = styled.span`
  max-width: 300px;
  font-size: 110%;
  margin: 0 10px;
  display: inline-block;
`

const RowData = styled.div`
  display: flex;
  flex-direction: row;
`

const DivColor = styled.div`
    background-color:${props => props.color};
    height: 20px;
    width: 20px;
`
