// go auth login 
export const goAuthApiServer = 'https://auth.innerlogic.com'
export const goAuthApiStaging = 'https://stg-auth-api.innerlogic.ca'
export const goAuthApiLocal = 'http://0.0.0.0:8080'

// app urls
export const serverApi = 'https://api.innerlogic.com'
export const staginApi = 'https://stg-api.innerlogic.ca'
export const localApi = 'http://127.0.0.1:8000'

// PRocess URL
export const processApiServer = 'https://chat-process.innerlogic.ca'
export const processApiStaging = 'https://stg-chat-process.innerlogic.ca'
export const processApiLocal = 'http://127.0.0.1:9000'
// 
export const serverUrl = "https://stg-admin.innerlogic.com"
export const Domain = ''

// Publishable key
export const Environment = 'local'
export const SentryDSN_DEV = "" 
export const SentryDSN_PROD = "https://6dbab634de5536ff86b3f12b9a317093@o1167548.ingest.sentry.io/4506310239518720"
export const SentryDSN_STG = "https://fa0d8d3febb5256464b1876fbb59a2e0@o1167548.ingest.sentry.io/4506310176145408"

export const CreateDemoResponses = true

export const StripeKey = 'pk_test_bowWtykmtAwZxvD0bvdXEycG00LczLxeDB'
// // to use production uncomment these lines
export const currentApi = serverApi
export const curGoApi = goAuthApiServer
export const curProcessUrl = processApiServer

// // to use staging uncomment these lines
// export const curGoApi = goAuthApiStaging
// export const currentApi = staginApi
// export const curProcessUrl = processApiStaging

// // to use local uncomment these lines
// export const curGoApi = goAuthApiLocal
// export const currentApi = localApi
// export const curProcessUrl = processApiLocal

export const config = {
    // switch to go for login
    loginapi: `${curGoApi}`,
    // use django api for login changed Oct 3 2022
    // loginapi: `${currentApi}/api/account`,
    // signupapi: `${currentApi}/api/signup`,
    // authapi: `${currentApi}/api/rest-auth`,
    // surveyapi: `${currentApi}/api/survey`,
    // appapi: `${currentApi}/api/organization`,
    appapi: `${currentApi}/api/support`,
    aiProcessUrl: `${curProcessUrl}`,
};
export default config

// npx browserslist@latest --update-db
// go-auth-api.innerlogic.ca
// go-trainwave-api.innerlogic.ca


// # LOCAL 'http://127.0.0.1:9000'
// # PROCESS_URL='http://127.0.0.1:9000'
