import {
  PENDING_EMPLOYEE,
  GET_EMPLOYEES,
  GET_EMPLOYE_CATEGORY_LIST,
  GET_EMPLOYEE_SELECT_LIST,
  UPDATE_EMPLOYEE_PAGE_NUMBER,
  SEARCH_EMPLOYEES,
  FETCH_EMPLOYEE,
  FETCH_USER_EMPLOYEE,
  EDIT_EMPLOYEE_PIC,
  DELETE_EMPLOYEE,
  RESET_EMPLOYEE,
  ADD_EMPLOYEE,
  EDIT_EMPLOYEE,
  VALIDATE_EMPLOYEE,
  SIGNUP_TOKEN,
  CLEAR_EMPLOYEE,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  employees: [],
  employee_category_list: [],
  employee_select_list: [],
  search: false,
  pageNumber: 1,
  employee_pics: [],
  pending: false,
  created: [],
  updated: false,
  userEmp: false,
  token: [],
  validateEmail: [],
};

export default function Employee(state = initialState, action) {
  switch (action.type) {
    case PENDING_EMPLOYEE:
      return {
        ...state,
        pending: true,
        created: [],
      };
    case UPDATE_EMPLOYEE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case GET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
        updated: false,
        pending: false,
      };
    case GET_EMPLOYE_CATEGORY_LIST:
      return {
        ...state,
        employee_category_list: action.payload,
        updated: false,
        pending: false,
      };
    case GET_EMPLOYEE_SELECT_LIST:
      return {
        ...state,
        employee_select_list: action.payload,
        updated: false,
        pending: false,
      };
    case SEARCH_EMPLOYEES:
      return {
        ...state,
        search: action.payload,
        pending: false,
        updated: false,
      };
    case VALIDATE_EMPLOYEE:
      return {
        ...state,
        validateEmail: action.payload,
        pending: false,
        updated: false,
      };

    case EDIT_EMPLOYEE_PIC:
      return {
        ...state,
        employee_pics: action.payload,
        [action.payload.id]: {
          ...state[action.payload.id],
          picture: action.payload.picture,
        },
        pending: false,
        updated: action.payload,
      };

    case FETCH_EMPLOYEE:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };

    case FETCH_USER_EMPLOYEE:
      return {
        ...state,
        userEmp: action.payload,
        pending: false,
        // created: []
      };

    // case EDIT_EMPLOYEE:
    //   return { ...state,
    //     [action.payload.id]: action.payload,
    //     pending: false };

    case EDIT_EMPLOYEE:
      if (state.employees.filter((an) => an.id === action.payload.id)[0]) {
        state = {
          ...state,
          employees: state.employees.filter(
            (emp) => emp.id !== action.payload.id
          ),
        };
      }
      return {
        ...state,
        [action.payload.id]: action.payload,
        employees: [...state.employees, action.payload],
        pending: false,
        updated: action.payload,
      };
    case DELETE_EMPLOYEE:
      return {
        ...state,
        employees: state.employees.filter((row) => row.id !== action.payload),
        pending: false,
        updated: true,
      };

    case ADD_EMPLOYEE:
      return {
        ...state,
        created: action.payload,
        employees: [...state.employees, action.payload],
        pending: false,
        updated: action.payload,
      };
    case SIGNUP_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case RESET_EMPLOYEE:
      return {
        ...state,
        employees: [],
        employee_category_list: [],
        employee_select_list: [],
        search: false,
        pageNumber: 1,
        employee_pics: [],
        pending: false,
        created: [],
        updated: false,
        userEmp: false,
        token: [],
        validateEmail: [],
      };
    case CLEAR_EMPLOYEE:
      return {
        ...state,
        employees: [],
        employee_category_list: [],
        employee_select_list: [],
        search: false,
        pageNumber: 1,
        employee_pics: [],
        pending: false,
        created: [],
        updated: false,
        userEmp: false,
        token: [],
        validateEmail: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        employees: [],
        employee_category_list: [],
        employee_select_list: [],
        search: false,
        pageNumber: 1,
        employee_pics: [],
        pending: false,
        created: [],
        updated: false,
        userEmp: false,
        token: [],
        validateEmail: [],
      };

    default:
      return state;
  }
}
