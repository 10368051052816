import {
  UPDATE_DEBRIEF_ANALYTICS_PAGE_NUMBER,
  GET_DEBRIEF_ANALYTICS_PENDING,
  GET_DEBRIEF_ANALYTICS,
  DELETE_DEBRIEF_ANALYTICS,
  FETCH_DEBRIEF_ANALYTICS,
  EDIT_DEBRIEF_ANALYTICS,
  ADD_DEBRIEF_ANALYTICS,
  CLEAR_DEBRIEF_ANALYTICS,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  debrief_analytics: [],
  pending: false,
  updated: [],
  pageNumber: 1,
};

export default function DebriefAnalytics(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DEBRIEF_ANALYTICS_PAGE_NUMBER:
      return {
        ...state,
        pending: false,
      };

    case GET_DEBRIEF_ANALYTICS_PENDING:
      return {
        ...state,
        pending: true,
      };
      
    case GET_DEBRIEF_ANALYTICS:
      return {
        ...state,
        debrief_analytics: action.payload,
        pending: false,
      };

    case FETCH_DEBRIEF_ANALYTICS:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };

    case EDIT_DEBRIEF_ANALYTICS:
      return {
        ...state,
        debrief_analytics: [...state, action.payload],
      };

    case DELETE_DEBRIEF_ANALYTICS:
      return {
        ...state,
        debrief_analytics: state.debrief_analytics.filter(
          (row) => row.id !== action.payload.id
        ),
      };

    case ADD_DEBRIEF_ANALYTICS:
      return {
        ...state,
        created: action.payload,
        debrief_analytics: [...state.debrief_analytics, action.payload],
        response: action.payload.response,
      };

    case CLEAR_DEBRIEF_ANALYTICS:
      return {
        ...state,
        debrief_analytics: [],
        pending: false,
        updated: [],
        pageNumber: 1,
      };

    case CLEAR_ALL:
      return {
        ...state,
        debrief_analytics: [],
        pending: false,
        updated: [],
        pageNumber: 1,
      };

    default:
      return state;
  }
}
