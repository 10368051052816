import {
    GET_ACCOUNTS,
    UPDATE_ACCOUNT_PAGE_NUMBER,
    FETCH_ACCOUNT,
    EDIT_ACCOUNT,
    DELETE_ACCOUNT,
    CLEAR_ACCOUNTS,
    CLEAR_ALL,
} from "constants/actions";

const initialState = {
    accounts: [],
    pageNumber: 1,
    deleted: false,
    pending: false,
};

export default function Account(state = initialState, action) {
    switch (action.type) {
        case GET_ACCOUNTS:
            return {
                ...state,
                accounts: action.payload,
                pending: false,
            };
        case UPDATE_ACCOUNT_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.payload
            };
        case FETCH_ACCOUNT:
            return {
                ...state,
                [action.payload.id]: action.payload,
                pending: false
            };
        case EDIT_ACCOUNT:
            return {
                ...state,
                [action.payload.id]: action.payload,
                pending: false
            };
        case DELETE_ACCOUNT:
            return {
                ...state,
                deleted: action.payload,
            };

        case CLEAR_ACCOUNTS:
            return {
                ...state,
                accounts: [],
                pending: false,
                deleted: false,
            };
        case CLEAR_ALL:
            return {
                ...state,
                accounts: [],
                pending: false,
                deleted: false,
            };
        default:
            return state;
    }
}
