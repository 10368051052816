import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import Start from "./Start.js";
import Score from "./Score.js";
import { Header, Icon } from "semantic-ui-react";

function RecommendationEval() {
  const [orgName, setOrgName] = useState(null);

  return (
    <>
      {ShowArrow(orgName)}
      <Routes>
        <Route path="/" element={<Start />} />
        <Route path="details/:id" element={<Score />} />
        <Route path="create" element={<Score />} />
      </Routes>
    </>
  );
}

export default RecommendationEval;

function ShowArrow(orgName) {
  const navigate = useNavigate();
  function BackArrow() {
    navigate("/app/sample-evaluation/");
  }

  return (
    <Header as="h2" style={{ marginTop: "20px" }}>
      <Icon
        style={{ cursor: "pointer" }}
        onClick={BackArrow}
        name="arrow left"
      />
      Rate recommendation
      <Header.Subheader>
        Rate recommendation and or models
      </Header.Subheader>
    </Header>
  );
}
