const endpoint = "https://api.openai.com/v1/chat/completions";  // Use the appropriate endpoint for your desired model.
const endpoint2 = "https://api.openai.com/v1/embeddings"

export default async function make_call(prompt){


        return fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer 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`
                },
                body: JSON.stringify({
                    messages: [{"role":"user","content":prompt}],
                    model:'gpt-3.5-turbo-0125'
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                return data.choices[0].message.content.trim()
            })
            .catch(error => {
                console.error("Error:", error);
            });
}

export async function embedding_call(prompt){


    return fetch(endpoint2, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer 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`
            },
            body: JSON.stringify({
                input: prompt,
                model:'text-embedding-ada-002',
                "encoding_format": "float"
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            return data.data[0]?.embedding
        })
        .catch(error => {
            console.error("Error:", error);
        });
}

