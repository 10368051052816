import React, {useEffect, useState} from "react";
import styled, { keyframes } from "styled-components";
import { Button, Dropdown, Input, Modal } from "semantic-ui-react";
import BasicInput from "./BasicInput";
import BulletPoints from "./BulletPoints";
import NumberedItems from "./NumberedItems";
import make_call from "./Request";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_CREATE_REPORT_TEMPLATE,
  SG_EDIT_REPORT_TEMPLATE,
  SG_ADD_REPORT_TEMPLATE,
  SG_DELETE_REPORT_TEMPLATE,
  CLEAR_REPORT_TEMPLATE
} from "constants/actions";

import { sample_outpout, description_sample, default_template, template_options, template_versions } from "./dataUsed";

const URL = 'report-templates'

const TemplateCreator = ({
  newResponse,
  setNewResponse,
  reportTemplate=null, // existing report template
  templateId = null,
}) => {
  const dispatch = useDispatch();
  const [template, setTemplate] = useState(default_template);
  const [selected, setSelected] = useState("");
  const [message, setMessage] = useState(sample_outpout);
  const [loading, setLoading] = useState(false);
  const [template_name, setTemplateName] = useState("");
  const [template_description, setTemplateDescription] =
    useState(description_sample);
  const navigate = useNavigate();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
const [savedId, setSavedId] = useState();
const [selectedVersion, setSelectedVersion] = useState(1);


  const { newTemplate, errors } = useSelector(
    (state) => ({
      newTemplate: state.report_template?.created,
      errors: state.errors,
    })
  );


  useEffect(() => {
    dispatch({
      type: CLEAR_REPORT_TEMPLATE,
      payload: {},
    })
  }, []);

  useEffect(() => {
    if (newTemplate && newTemplate?.id) {
      setSavedId(newTemplate.id);
    }

  }, [newTemplate])

  useEffect(() => {
    if (errors?.message) {
      toast.error('Something went wrong while saving the template');
    }
  }, [])


  const addTemplate = () => {
    if (selected !== "") {
      const newTemplateItem = { ...template_options[selected] };

      // Add the new object to the template array
      setTemplate([...template, newTemplateItem]);

      // Clear the selected option
      setSelected("");
    }
  };

  useEffect(() => {
    if (reportTemplate) {
      setTemplate(reportTemplate.template?.[0]?.template || reportTemplate.template)
      setTemplateName(reportTemplate.name)
      setTemplateDescription(reportTemplate.template?.[0]?.description)
    }
  }, [reportTemplate])
  
  function deleteReportTemplate() {
    dispatch({
      type: SG_DELETE_REPORT_TEMPLATE,
      payload: {
        id: templateId,
      },
    });
    setNewResponse(Math.random());
    navigate(`/app/${URL}`);
  }

  console.log("selectedVersion", selectedVersion)
  // fields
  // "id => auto created
  // "name = > required string 250
  // "status => number (1, "New"),(9, "Live"), (99, "Archived"),
  // "template", => json file
  // "sort_order", => required and needs to be unique

  function SaveReportTemplate() {
    // need to add validations
    if (templateId || newTemplate?.id) {
      // SG_EDIT_REPORT_TEMPLATE
      const updateId = templateId || newTemplate?.id;
      dispatch({
        type: SG_EDIT_REPORT_TEMPLATE,
        payload: {
          id: updateId,
          name: template_name,
          api_version: selectedVersion,
          type_of: 1,
          status: 9,
          template: [{
            description: template_description,
            template: template
          }],
        },
      });
      toast.success('Template updated successfully');
    } else {
      dispatch({
        type: SG_ADD_REPORT_TEMPLATE,
        payload: {
          name: template_name,
          api_version: selectedVersion,
          status: 9,
          type_of: 1,
          template: [{
            description: template_description,
            template: template
          }],
        },
      });
      toast.success('Template created successfully');
    }

    setNewResponse(Math.random());
  }

  const Save = (text, index) => {
    const _template = [...template];
    _template[index].input_text = text;
    setTemplate(_template);
  };

  const deleteTemplate = (index) => {
    console.log(`Deleting item at index: ${index}`); // Debug log
    const _template = [...template];
    _template.splice(index, 1);
    setTemplate(_template);
  };

  const moveTemplateUp = (index) => {
    console.log(`Moving item at index: ${index} up`); // Debug log
    const _template = [...template];
    const temp = _template[index];
    _template[index] = _template[index - 1];
    _template[index - 1] = temp;
    setTemplate(_template);
  };

  const moveTemplateDown = (index) => {
    console.log(`Moving item at index: ${index} down`); // Debug log
    const _template = [...template];
    const temp = _template[index];
    _template[index] = _template[index + 1];
    _template[index + 1] = temp;
    setTemplate(_template);
  };

  const handleTemplatePromptChange = (value) => {
    setTemplateDescription(value);
  };

  const make_gpt_call = () => {
    setLoading(true);
    SaveReportTemplate();
    async function someFunction(prompt) {
      try {
      const message = await make_call(prompt, template_description);
      setMessage(message);
      setLoading(false);
      } catch (error) {
        console.log(error);
      }


    }

    someFunction(template);
  };

  //   const save_template = () => {
  //     const data = {
  //       template_name: template_name,
  //       template_description: template_description,
  //       template: template,
  //     };
  //     onSave(data);
  //   };

  const deleteTemplateConfirm = (
    <Modal
      onClose={() => setShowDeleteConfirm(false)}
      onOpen={() => setShowDeleteConfirm(true)}
      open={showDeleteConfirm}
      size="mini"
      closeOnDimmerClick
    >
      <Modal.Content>
        <Modal.Description>
          <Header>Delete Template?</Header>
          <p>Are you sure you want to delete this template?</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <ActionWrapper>
          <CancelText color="black" onClick={() => setShowDeleteConfirm(false)}>
            Cancel
          </CancelText>
          <DeleteConfirm onClick={() => deleteReportTemplate()}>
            Delete
          </DeleteConfirm>
        </ActionWrapper>
      </Modal.Actions>
    </Modal>
  );

  const getPlaceholder = (value) => {
    switch (value) {
      case 4:
        return "Enter the ID of the data anchor that goes into this table.";
      case 5:
        return "Enter a factor title";
      default:
        return "Describe in detail, what information should be present here.";
    }
  };

  return (
    <Container>
      {deleteTemplateConfirm}
      <Left>
        <Title>Build a Template</Title>

        <Label>Template Version</Label>
        <Dropdown
          placeholder="Select a template version"
          fluid
          selection
          value={selectedVersion}
          onChange={(e, { value }) => setSelectedVersion(value)}
          options={template_versions}
        />

        <InputArea>
          <Label>Template name</Label>
          <Input
            placeholder="Enter a title"
            value={template_name}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </InputArea>

        <InputArea>
          <Label>Template prompt</Label>
          <TextArea
            placeholder="Enter a prompt that gives this template its goal and purpose..."
            value={template_description}
            onChange={(e) => handleTemplatePromptChange(e.target.value)}
          />
        </InputArea>


        <Label>Template element</Label>
        <Dropdown
          placeholder="Select a template"
          fluid
          selection
          value={selected}
          onChange={(e, { value }) => setSelected(value)}
          options={template_options}
        />

        <Button onClick={() => addTemplate()}>Add element</Button>

        <TemplateContainer>
          {template?.map((t, i) => {
            const placeholder = getPlaceholder(t?.value);
            if (t?.value === 3) {
              return (
                <BulletPoints
                  key={i}
                  type={t?.text}
                  index={t?.value}
                  deleteTemplate={() => deleteTemplate(i)}
                  moveTemplateUp={() => moveTemplateUp(i)}
                  moveTemplateDown={() => moveTemplateDown(i)}
                  saved_value={t?.input_text}
                  placeholder={placeholder}
                  onChange={(text) => Save(text, i)}
                />
              );
            }

            if (t?.value === 7) {
              return (
                <NumberedItems
                  key={i}
                  type={t?.text}
                  index={t?.value}
                  deleteTemplate={() => deleteTemplate(i)}
                  moveTemplateUp={() => moveTemplateUp(i)}
                  moveTemplateDown={() => moveTemplateDown(i)}
                  saved_value={t?.input_text}
                  placeholder={placeholder}
                  onChange={(text) => Save(text, i)}
                />
              )
            }

            return (
              <BasicInput
                key={i}
                type={t?.text}
                index={t?.value}
                deleteTemplate={() => deleteTemplate(i)}
                moveTemplateUp={() => moveTemplateUp(i)}
                moveTemplateDown={() => moveTemplateDown(i)}
                saved_value={t?.input_text}
                placeholder={placeholder}
                onChange={(text) => Save(text, i)}
              />
            );
          })}
        </TemplateContainer>
      </Left>

      <Right>
        <Toaster />
        <Title>Preview Template</Title>
        <Row>
          <Button onClick={() => make_gpt_call()}>Generate Preview</Button>
          <Button onClick={() => SaveReportTemplate()}>Save Template</Button>
          {templateId && <Button onClick={() => setShowDeleteConfirm(true)}>Delete Template</Button>}
        </Row>

        {loading ? "Loading..." : ""}
        {message ? <FormatData jsonString={message} /> : ""}
      </Right>
    </Container>
  );
};

export default TemplateCreator;

const FormatData = ({ jsonString }) => {
  let dataArray;
  try {
    dataArray = JSON.parse(jsonString);
  } catch (error) {
    return <p>Error parsing JSON data: {error.message}</p>;
  }

  const renderElements = (items) => {
    const elements = [];
    let keyCounter = 0;

    items.forEach((item) => {
      switch (item.type) {
        case "t1":
          elements.push(<h1 key={keyCounter++}>{item.text}</h1>);
          break;
        case "t2":
          elements.push(<h2 key={keyCounter++}>{item.text}</h2>);
          break;
        case "p":
          elements.push(<p key={keyCounter++}>{item.text}</p>);
          break;
        case "b":
          // If the previous element is not a list, start a new list
          if (!elements.length || elements[elements.length - 1].type !== "ul") {
            elements.push(
              <ul key={keyCounter++}>
                {[<li key={keyCounter++}>{item.text}</li>]}
              </ul>
            );
          } else {
            // If the previous element is a list, add the bullet point to it
            let lastElement = elements.pop();
            lastElement = React.cloneElement(lastElement, {
              children: [
                ...lastElement.props.children,
                <li key={keyCounter++}>{item.text}</li>,
              ],
            });
            elements.push(lastElement);
          }
          break;
        case "t3":
          elements.push(<h3 key={keyCounter++}>{item.text}</h3>);
          break;
        case "n":
            // If the previous element is not a list, start a new list
          if (!elements.length || elements[elements.length - 1].type !== "ol") {
            if (Array.isArray(item?.text)) {
              let numberedItems = item?.text.map((text) => {
                return <li key={keyCounter++}>{text}</li>;
              });
              elements.push(
                <ol key={keyCounter++}>
                  {numberedItems}
                </ol>
              );
            } else {
              elements.push(
                <ol key={keyCounter++}>
                  {[<li key={keyCounter++}>{item.text}</li>]}
                </ol>
              );
            }
            } else {
              // If the previous element is a list, add the bullet point to it
              let numberElement = elements.pop();
              numberElement = React.cloneElement(numberElement, {
                children: [
                  ...numberElement.props.children,
                  <li key={keyCounter++}>{item.text}</li>,
                ],
              });
              elements.push(numberElement);
            }
            break;
        default:
          break;
      }
    });

    return elements;
  };

  return <div style={{ marginTop: 30 }}>{renderElements(dataArray)}</div>;
};

const Container = styled.div`
  margin-top: 30px;
  padding-left: 58px;
  font-family: "Raleway";
  display: flex;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-family: "Raleway";
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-size: 12px;
  font-family: "Raleway";
  color: #666d79;
`;
const TemplateContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`;

const TextArea = styled.textarea`
  height: 100px;
  width: 100%;
  padding: 10px;
  font-family: "Raleway";
  line-height: 1.3;
  border: 1px solid #e0e0e0;
`;
const InputArea = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Left = styled.div`
  width: 50%;
  padding-right: 20px;
`;

const Right = styled.div`
  width: 50%;
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
`;

const Header = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  color: black;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

const CancelText = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  padding-right: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #666d79;
  cursor: pointer;
`;

const DeleteConfirm = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  padding-right: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #2d70e2;
  cursor: pointer;
`;


