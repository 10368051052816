import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const ControlledInput = (props) => {
   const { value, onChange, ...rest } = props;
   const [cursor, setCursor] = useState(null);
   const ref = useRef(null);

   useEffect(() => {
      const input = ref.current;
      if (input) input.setSelectionRange(cursor, cursor);
   }, [ref, cursor, value]);

   const handleChange = (e) => {
      setCursor(e.target.selectionStart);
      onChange && onChange(e);
   };

   return <Input
   style={{width:'100%'}}
   ref={ref} value={value} onChange={handleChange} {...rest} />;
};

const Input = styled.input`
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 16px;
    font-family: 'Raleway';
    margin-bottom: 10px;
    height: 38px;
    width: 100%;
    box-sizing: border-box;
    &:focus {
        outline: none;
        border: 1px solid #89B8DA;
    }
`;

export default ControlledInput;