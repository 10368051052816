import React, { useEffect } from "react";
import styled from "styled-components";

import { Modal,Button } from "semantic-ui-react";


const Question = ({open,setOpen,setQuestion,message,submit}) =>{

    const onSubmit = () =>{

        submit()
        setOpen(false)
    }

    return (
        <Modal
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        size="medium"
        // trigger={<Button>Scrolling Content Modal</Button>}
      >
        <Container>
            <Title>Identified Need</Title>
            <p style={{marginBottom:40}}>{message}</p>

            <Q>If you could ask a single follow up question to the leader we're building 
                a recommendation for based on the needs analysis, what question would you ask?</Q>

            <TextInput 
            placeholder="Enter your question here" 
            onChange={(e)=>setQuestion(e.target.value)}/>

            <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={()=>onSubmit()}>Save</Button>
            </div>
        </Container>
    </Modal>
    )
}


export default Question;


const Container = styled.div`
    padding:20px;
    font-family:'Raleway';
`

const Title = styled.div`
    font-size:20px;
    font-weight:bold;
    margin-bottom:20px;
    line-height:1.3;
`

const TextInput = styled.input`
    width:100%;
    height:50px;
    border-radius:10px;
    border:1px solid #ccc;
    padding:10px;
    font-size:16px;
    margin-bottom:20px;
    margin-top:20px;

`

const Q = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:10px;
    line-height:1.3;
`