import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Select from "react-select";

import {
  SG_GET_REPORT_TEMPLATE_SELECT,
  SG_GET_RECOMMENDATION_MODELS,
  SG_ADD_GPT_RECOMMENDATION,
} from "constants/actions";

const NewRecommendation = ({ debriefId, organizationId }) => {
  const dispatch = useDispatch();
  const [reportTemplate, setReportTemplate] = useState([]);
  const [recommendationModels, setRecommendationModels] = useState([]);
  const [reportTemplateSelected, setReportTemplateSelected] = useState(null);
  const [recommendationModelsSelected, setRecommendationModelsSelected] =
    useState(null);
  const [gptRecommendationCreated, setGptRecommendationCreated] = useState([]);

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);

  const {
    get_recommendation_models,
    get_report_template,
    get_gpt_recommendation,
  } = useSelector(
    (state) => ({
      get_recommendation_models: state.recommendation_models,
      get_report_template: state.report_template,
      get_gpt_recommendation: state.gpt_recommendation,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: SG_GET_RECOMMENDATION_MODELS,
    });
    dispatch({
      type: SG_GET_REPORT_TEMPLATE_SELECT,
    });
  }, [dispatch]);

  useEffect(() => {
    setRecommendationModels(
      get_recommendation_models?.recommendation_models?.response
    );
  }, [get_recommendation_models]);

  useEffect(() => {
    setReportTemplate(get_report_template?.select);
  }, [get_report_template]);

  useEffect(() => {
    setGptRecommendationCreated(get_gpt_recommendation?.created);
  }, [get_gpt_recommendation]);

  useEffect(() => {
    const ErrorList = [];
    let totalErrors = 0;


      if (!recommendationModelsSelected?.name) {
        ErrorList["external_model"] = {
          error: true,
          message: "Please select a Model",
        };
        totalErrors += 1;
      }
      if (!reportTemplateSelected?.id) {
        ErrorList["recommendation_template"] = {
            error: true,
            message: `Please select a Recommendation Template`,
          };
          totalErrors += 1;
      }

    ErrorList["errors"] = totalErrors;
    setFormErrors(ErrorList);
  }, [pristine, recommendationModelsSelected, reportTemplateSelected]);

  function CreateNewRecommendation() {
    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }

    dispatch({
      type: SG_ADD_GPT_RECOMMENDATION,
      payload: {
        // employee: employeeId,
        debrief_schedule: debriefId,
        organization: organizationId,
        external_model: recommendationModelsSelected?.name,
        recommendation_template: reportTemplateSelected?.id,
      },
    });

    setPristine(true);
  }

  console.log(recommendationModels);
  console.log(reportTemplate);
  console.log(gptRecommendationCreated);
  console.log(recommendationModelsSelected);
  console.log(reportTemplateSelected);
console.log(formErrors);

  return (
    <Container>
      <Title as="h3">Generate Chat GTP Recommendation</Title>
      <Row>
        <Column>
          <SubTitle>Model </SubTitle>
          <Select
            placeholder={`Select Model`}
            onChange={setRecommendationModelsSelected}
            value={recommendationModelsSelected}
            options={recommendationModels}
            // isLoading={isLoading === variable ? isLoading : false}
            getOptionLabel={(recommendationModels) =>
              recommendationModels?.folder
            }
            getOptionValue={(recommendationModels) =>
              recommendationModels?.folder
            }
          />
          <p>
            The Models are a list of folders which have description.json in them{" "}
          </p>
          {formErrors?.external_model?.error && !pristine && (
            <ErrorMessage>{formErrors?.external_model?.message}</ErrorMessage>
          )}
        </Column>
        <Column>
          <SubTitle>Report Template</SubTitle>
          <Select
            placeholder={`Select Template`}
            onChange={setReportTemplateSelected}
            value={reportTemplateSelected}
            options={reportTemplate}
            // isLoading={isLoading === variable ? isLoading : false}
            getOptionLabel={(reportTemplate) => reportTemplate?.name}
            getOptionValue={(reportTemplate) => reportTemplate?.id}
          />
          <p>These are list of templates</p>
          {formErrors?.recommendation_template?.error && !pristine && (
            <ErrorMessage>
              {formErrors?.recommendation_template?.message}
            </ErrorMessage>
          )}
        </Column>

        <CenteredDiv>
          <Button onClick={CreateNewRecommendation}>Queue Generation</Button>
        </CenteredDiv>
      </Row>
      {gptRecommendationCreated?.id && (
        <Row>
          <Title>
            {" "}
            Queued with ID #{gptRecommendationCreated?.tracking_code} Please
            check back in a while
          </Title>
        </Row>
      )}
    </Container>
  );
};

export default NewRecommendation;

const Container = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border: 1px solid #dcdcdc;
  border-radius: 5px; /* Add border radius here */
`;

const ErrorMessage = styled.p`
    color: red;
`

const Title = styled.p`
  font-size: 20px;
  //   font-weight: bold;
  margin-bottom: 10px;
`;

const SubTitle = styled.p`
  font-size: 18px;
  //   font-weight: bold;
  margin-bottom: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex: 1;
  padding: 10px;
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
`;
