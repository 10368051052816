export const sample_outpout = `[{
	"type": "t1",
	"text": "Vision & Values Alignment Plan"
}, {
	"type": "p",
	"text": "The plan aims to enhance clarity around the organization's vision and values. By establishing a shared purpose, we increase team cohesion and innovation. We leverage diverse skills and shared goals to drive performance and growth."
}, {
	"type": "t2",
	"text": "Cultivating Team Cohesion"
}, {
	"type": "p",
	"text": "To address cultural ambiguity, initiate a 'Values Integration Program'. This will target the alignment of daily functions with broader organizational goals. Emphasize the importance of shared values and mission commitment. Create forums for discussion to establish common ground, leading to a more cohesive and united workforce."
}, {
	"type": "b",
	"text": "Facilitate regular team meetings to discuss how daily activities contribute to the organization's vision. Encourage open conversation about the relevance of workloads to shared objectives."
}, {
	"type": "b",
	"text": "Devise team-building activities that focus on recognizing diverse skills and perspectives. These should celebrate contributions that align with the company's values and advance its mission."
}, {
	"type": "b",
	"text": "Invite guest speakers and leaders to share stories of how adherence to values led to success, reinforcing the significance of a unified organizational culture."
}, {
	"type": "t2",
	"text": "Promoting Skills-Based Transformation"
}, {
	"type": "p",
	"text": "Shift the emphasis from individual performance to team-based accomplishments. Specifically recognize efforts that illustrate the company’s values, fostering an environment where diverse talents converge on shared goals. Address diversity-related challenges by introducing initiatives that respect differing viewpoints."
}, {
	"type": "b",
	"text": "Implement a 'Recognition Program' that highlights teams for collective achievements, especially when they reflect the organization's vision. Acknowledge their creative solutions and innovative approaches."
}, {
	"type": "b",
	"text": "Conduct workshops on social sensitivity and effective communication to harness the full advantage of diversity. Equip your team with the understanding and the ability to resolve conflicts constructively."
}, {
	"type": "b",
	"text": "Develop a mentoring program where employees share skills and insights relevant to the organization's mission. This will help bridge skill gaps and align personal growth with company objectives."
}]`;

export const description_sample =
  "Build a plan which is aimed at providing 1 on 1 meetings. This plan should help solve the need identified but in the context of 1 on 1 meetings.";


  export const template_versions = [
    {
      key: "v1 - Default",
      text: "v1 - Default",
      value: 1,
    },
    {
      key: "v2",
      text: "v2",
      value: 2,
    },
    {
      key: "v3",
      text: "v3",
      value: 3,
    },
];

  export const template_options = [
    {
      key: "Title",
      text: "Title",
      value: 0,
    },
    {
      key: "Secondary Title",
      text: "Secondary Title",
      value: 1,
    },
    {
      key: "Paragraph",
      text: "Paragraph",
      value: 2,
    },
    {
      key: "Bullets",
      text: "Bullets",
      value: 3,
    },
    {
      key: "Data Table",
      text: "Data Table",
      value: 4,
    },
    {
      key: "Factor Bar",
      text: "Factor Bar",
      value: 5,
    },
    {
      key: "Tertiary Title",
      text: "Tertiary Title",
      value: 6,
    },
    {
      key: "Numbered List",
      text: "Numbered List",
      value: 7,
    }
  ];
  
 export const default_template = [
    {
      key: "Title",
      text: "Title",
      value: 0,
      input_text: "Title for the plan, 50 characters max.",
    },
    {
      key: "Paragraph",
      text: "Paragraph",
      value: 2,
      input_text: "A summary of the overall plan details. 3-4 sentences.",
    },
    {
      key: "Secondary Title",
      text: "Secondary Title",
      value: 1,
      input_text: "Title for the first solution",
    },
    {
      key: "Paragraph",
      text: "Paragraph",
      value: 2,
      input_text: "A description of the first solution. 5 sentences long.",
    },
    {
      key: "Bullets",
      text: "Bullets",
      value: 3,
      input_text: [
        "Action step 1 for the solution described above. 2-3 sentences.",
        "Action step 2, adds to step 1  in. 2-3 sentences",
        "Action step 3, adds to step 2. 2-3 sentences.",
      ],
    },
    {
      key: "Secondary Title",
      text: "Secondary Title",
      value: 1,
      input_text: "Title for the 2nd solution",
    },
    {
      key: "Paragraph",
      text: "Paragraph",
      value: 2,
      input_text: "A description of the second solution. 5 sentences long.",
    },
    {
      key: "Bullets",
      text: "Bullets",
      value: 3,
      input_text: [
        "Action step 1 for the solution described above. 3-4 sentences.",
        "Action step 2, build on step 1  in. 3-4 sentences",
        "Action step 3, builds on step 2. 3-4 sentences.",
      ],
    },
  ];
  