import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Card } from 'semantic-ui-react'

import { format } from 'date-fns'
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_FETCH_DEBRIEF_TOKEN } from "constants/actions";
// 
//
const Details = (
  { briefToken }
) => {
  const dispatch = useDispatch();
  const [briefResponse, setBriefResponse] = useState(null);

  useEffect(() => {
    if (briefToken) {
      dispatch({
        type: SG_FETCH_DEBRIEF_TOKEN,
        payload: {id: briefToken}
      });
    }
  }, [dispatch, briefToken]);

  const { get_brief_token } = useSelector(
    (state) => ({
      get_brief_token: state.debrief_token,
    }),
    shallowEqual
  );

  useEffect(() => {
    setBriefResponse(get_brief_token[briefToken])
  }, [get_brief_token, briefToken]);


  return (
    <>
      <Card fluid >
        <Card.Content>
          <Container>
          <ListContainer>
              <HeaderDiv>Brief Token Id
              </HeaderDiv>
              <ContentDiv>
                {briefResponse?.id}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Employee Id
              </HeaderDiv>
              <ContentDiv>
                {briefResponse?.employee}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Status
              </HeaderDiv>
              <ContentDiv>
                {briefResponse?.survey_status}
              </ContentDiv>
            </ListContainer>

            <ListContainer>
              <HeaderDiv>Created Date</HeaderDiv>
              <ContentDiv>
                {briefResponse?.created_at && format(new Date(briefResponse?.created_at), 'dd-MMM-yyyy hh:mm')}
              </ContentDiv>
            </ListContainer>

            <ListContainer>
              <HeaderDiv>Updated</HeaderDiv>
              <ContentDiv>
                {briefResponse?.updated_at && format(new Date(briefResponse?.updated_at), 'dd-MMM-yyyy hh:mm')}
              </ContentDiv>
            </ListContainer>
            
          </Container>
          <Container>
            <ListContainer>
              <HeaderDiv>Response</HeaderDiv>
              <ContentDiv>
                <pre>
                  {JSON.stringify(briefResponse?.stored_response, null, 2)}
                </pre>
              </ContentDiv>
            </ListContainer>
          </Container>
          <Container>

          </Container>
        </Card.Content>
      </Card>

    </>
  );
};

export default Details;

const Container = styled.div`
  display: flex;
`
const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`

const ContentDiv = styled.div`
  // background-color: #b6701e;

`