import React from 'react';
import styled from 'styled-components';

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_EDIT_DEBRIEF_SCHEDULE, CLEAR_ERRORS } from "constants/actions";

import BasicErrorMessage from "utilities/ErrorMessages";

import { CustomMessagesList } from "Messages/Errors/Message";

import {
    Button,
  } from "semantic-ui-react";

  
const AdditionalType = ({ data, debriefDetailsId }) => {
    const dispatch = useDispatch();

    function Submit() {
        dispatch({
          type: SG_EDIT_DEBRIEF_SCHEDULE,
          payload: {
            id: debriefDetailsId,
            update_additional_type: "yes",
          },
        });
      }

  return (
    <Container>
         <BasicErrorMessage />

      <SectionTitle>Feedback Builder</SectionTitle>
      <ContentRow>
        <Label>Question:</Label>
        <Value>{data?.feedback_builder?.question?.label}</Value>
        <Label>Sort Order:</Label>
        <Value>{data?.feedback_builder?.question?.sort_order}</Value>
        <Label>Survey Type:</Label>
        <Value>{data?.feedback_builder?.question?.survey_type}</Value>
        <Label>Ask Again:</Label>
        <Value>{data?.feedback_builder?.ask_again?.label}</Value>
        
      </ContentRow>
      <SectionTitle>Outcome Question</SectionTitle>
      <ContentRow>
        <Label>Question:</Label>
        <Value>{data?.outcome_question?.question?.label}</Value>
        <Label>Sort Order:</Label>
        <Value>{data?.outcome_question?.question?.sort_order}</Value>
        <Label>Survey Type:</Label>
        <Value>{data?.outcome_question?.question?.survey_type}</Value>
        <Label>Ask Again:</Label>
        <Value>{data?.outcome_question?.ask_again?.label}</Value>
        
      </ContentRow>
      <ContentRow>
        <Label>This would change delivery of questions POST this change: </Label>
        <Button content="Update Types" negative onClick={Submit} />
      </ContentRow>
      
    </Container>
  );
};

export default AdditionalType;

const Container = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ContentRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const SectionTitle = styled.h3`
  color: #333;
  margin-top: 0;
`;

const Label = styled.strong`
  color: #555;
`;

const Value = styled.div``;
