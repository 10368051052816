import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  PENDING_SURVEY_STRUCTURE,
  GET_SURVEY_STRUCTURES,
  SG_GET_SURVEY_STRUCTURES,
  COPY_SURVEY_STRUCTURE,
  SG_COPY_SURVEY_STRUCTURE,
  SURVEY_STRUCTURE_SELECT,
  SG_SURVEY_STRUCTURE_SELECT,
  FETCH_SURVEY_STRUCTURE,
  SG_FETCH_SURVEY_STRUCTURE,
  EDIT_SURVEY_STRUCTURE,
  SG_EDIT_SURVEY_STRUCTURE,
  ADD_SURVEY_STRUCTURE,
  SG_ADD_SURVEY_STRUCTURE,
  DELETE_SURVEY_STRUCTURE,
  SG_DELETE_SURVEY_STRUCTURE,
  ALL_ERRORS,
} from "constants/actions";


function* loadmethods(action) {
    yield put({ type: PENDING_SURVEY_STRUCTURE });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_SURVEY_STRUCTURES, action.payload);
    // console.log(json.data)
    yield put({ type: GET_SURVEY_STRUCTURES, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_SURVEY_STRUCTURES, loadmethods);
}

function* loadSelect(action) {
  yield put({ type: PENDING_SURVEY_STRUCTURE });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_SURVEY_STRUCTURE_SELECT, action.payload);
    // console.log(json.data)
    yield put({ type: SURVEY_STRUCTURE_SELECT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* selectLoad() {
  yield takeLatest(SG_SURVEY_STRUCTURE_SELECT, loadSelect);
}

function* fetchmethods(action) {
  yield put({ type: PENDING_SURVEY_STRUCTURE });
  try {
    const json = yield call(api.FETCH_SURVEY_STRUCTURE, action.payload);
    yield put({ type: FETCH_SURVEY_STRUCTURE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_SURVEY_STRUCTURE, fetchmethods);
}

function* duplicatemethods(action) {
  yield put({ type: PENDING_SURVEY_STRUCTURE });
  try {
    const json = yield call(api.COPY_SURVEY_STRUCTURE, action.payload);
    yield put({ type: COPY_SURVEY_STRUCTURE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDuplicate() {
  yield takeLatest(SG_COPY_SURVEY_STRUCTURE, duplicatemethods);
}

function* updatemethods(action) {
  yield put({ type: PENDING_SURVEY_STRUCTURE });
  try {
    const json = yield call(api.EDIT_SURVEY_STRUCTURE, action.payload);
    yield put({ type: EDIT_SURVEY_STRUCTURE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodUpdate() {
  yield takeEvery(SG_EDIT_SURVEY_STRUCTURE, updatemethods);
}

function* addmethods(action) {
  yield put({ type: PENDING_SURVEY_STRUCTURE });
  try {
    const json = yield call(api.ADD_SURVEY_STRUCTURE, action.payload);
    yield put({ type: ADD_SURVEY_STRUCTURE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_SURVEY_STRUCTURE, addmethods);
}

function* deletemethods(action) {
  yield put({ type: PENDING_SURVEY_STRUCTURE });
  try {
    yield call(api.DELETE_SURVEY_STRUCTURE, action.payload);
    yield put({ type: DELETE_SURVEY_STRUCTURE, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_SURVEY_STRUCTURE, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    selectLoad(),
    methodDuplicate(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
