import {
  GET_ORGANIZATION_PENDING,
  GET_ORGANIZATIONS,
  GET_ORGANIZATION_VIEWED,
  GET_ORGANIZATION_SELECT,
  FETCH_ORGANIZATION_PRIMARY,
  FETCH_ORGANIZATION,
  UPDATE_ORGANIZATION_PAGE_NUMBER,
  DELETE_ORGANIZATION,
  ADD_ORGANIZATION,
  GET_ORGANIZATION_STYLE,
  EDIT_ORGANIZATION,
  EDIT_ORGANIZATION_LOGO,
  VALIDATE_ORGANIZATION,
  CLEAR_ORGANIZATION,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  organizations: [],
  organization: [],
  organization_viewed: false,
  organization_select: [],
  organization_style: [],
  organization_logo: [],
  pending: false,
  validation: [],
  pageNumber: 1,
};

export default function Organization(state = initialState, action) {
  switch (action.type) {
    case GET_ORGANIZATION_PENDING: 
    return {
      ...state,
      pending: true,
    }
    case GET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
        pending: false,
      };
    case GET_ORGANIZATION_SELECT:
      return {
        ...state,
        organization_select: action.payload,
        pending: false,
      };
      case FETCH_ORGANIZATION_PRIMARY:
        return {
          ...state,
          primary_select: action.payload,
          pending: false,
        };
      case GET_ORGANIZATION_STYLE:
        return {
          ...state,
          organization_style: action.payload,
          pending: false,
        };
    case GET_ORGANIZATION_VIEWED:
        return {
          ...state,
          organization_viewed: action.payload,
          pending: false,
        };
    case UPDATE_ORGANIZATION_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload
      }
    case FETCH_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
        [action.payload.id]: action.payload, pending: false
      };

    case EDIT_ORGANIZATION_LOGO:
      const newState = { ...state };
      newState.organization['logo'] = action.payload.logo
      return {
        ...state,
        organization_logo: action.payload,
        pending: false
      };
    case EDIT_ORGANIZATION:
      return { ...state, [action.payload.id]: action.payload, pending: false };
    case VALIDATE_ORGANIZATION:
      return {
        ...state,
        validation: action.payload,
        pending: false,
      };
    case DELETE_ORGANIZATION:
      return {
        ...state,
        organizations: state.organizations.results.filter(
          (row) => row.id !== action.payload
        ),
      };

    case ADD_ORGANIZATION:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    case CLEAR_ORGANIZATION:
      return {
        ...state,
        organizations: [],
        organization: [],
        organization_viewed: false,
        organization_select: [],
        organization_style: [],
        organization_logo: [],
        pending: false,
        validation: [],
        pageNumber: 1,
      };

    case CLEAR_ALL:
      return {
        ...state,
        organizations: [],
        organization: [],
        organization_viewed: false,
        organization_select: [],
        organization_style: [],
        organization_logo: [],
        pending: false,
        validation: [],
        pageNumber: 1,
      };

    default:
      return state;
  }
}
