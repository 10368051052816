import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { format } from 'date-fns'

import { Card, Modal, Header, Button, Icon, Input, Message } from 'semantic-ui-react'
import SurveyResponse from "briefResponse/Details"
import SurveyProgress from "briefToken/Progress"
import AnimationPortal from "utilities/AnimationPortal";
import {
  SG_SEND_DEBRIEF_TOKEN_EMAIL,
} from "constants/actions";

function ValidateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return (true)
  }
  return (false)
}

function SendDemoEmail({ tokenId, DelayedLoad, setShowMessage }) {
  const dispatch = useDispatch();
  const [emailId, setEmailId] = useState(false)

  function SendTokenEmail() {
    if (emailId) {
      if (ValidateEmail(emailId)) {
        dispatch({
          type: SG_SEND_DEBRIEF_TOKEN_EMAIL,
          payload: {
            brief_token_id: tokenId,
            email_id: emailId
          },
        });
        DelayedLoad()
        setShowMessage({
          color: "green",
          message: `Email sent sucessfully to ${emailId}`,
          header: "Email sent"
        })
      } else {
        setShowMessage({
          color: "orange",
          message: `Invalid Email address, please enter correct one`,
          header: "Invalid Email"
        })
      }

    } else {
      setShowMessage({
        color: "red",
        message: `Email id required`,
        header: "Email NOT sent"
      })
    }
  }

  return (
    <>
      <Input type="email"
        value={emailId || ''}
        onChange={(e) => setEmailId(e.target.value)} />
      <Button onClick={SendTokenEmail}>Send</Button>
    </>
  )
}

const RowCard = ({ row, isDemo, DelayedLoad }) => {

  const [openPortal, setOpenPortal] = useState(false)
  const [openProgress, setOpenProgress] = useState(false)
  const [briefToken, setbriefToken] = useState(false)
  const [showMessage, setShowMessage] = useState(false)

  function ShowResponse(id) {
    setbriefToken(id)
    setOpenPortal(true)
  }

  function ShowProgress(id) {
    setbriefToken(id)
    setOpenProgress(true)
  }

  return (
    <>
      <Card fluid key={row?.id}>
        {showMessage &&
          <Message
            onDismiss={() => setShowMessage(false)}
            color={showMessage.color}
            header={showMessage?.header}
            content={showMessage?.message}
          />
        }

        <Card.Content>
          <EvenlySpaced>
            <div>
              Id # {row?.id}
            </div>
            <div>
              Token # {row?.token}
            </div>
            <div>
              {isDemo && <SendDemoEmail
                tokenId={row?.id}
                DelayedLoad={DelayedLoad}
                setShowMessage={setShowMessage}
              />}
            </div>
          </EvenlySpaced>
          <Container>
            <RowData>
              <ListContainer>
                <HeaderDiv>
                  Delivery
                  {/* {row?.sent && `Sent at ${format(new Date(row?.sent), 'dd-MMM-yyyy hh:mm')} with ${row?.reminded} reminders`
                  } */}
                </HeaderDiv>
                <ContentDiv>
                  <AnimationPortal fullContent={{
                    contentId: row?.id,
                    header: `Delivery Details Id # ${row?.id}`,
                    content: `${row?.delivery_status}`
                  }} id={row?.id} />

                </ContentDiv>
              </ListContainer>

              <ListContainer>
                <HeaderDiv>Validation Token</HeaderDiv>
                <ContentDiv>  {row?.validation_token ? ` ## ${row?.validation_token}` : "Not started"} </ContentDiv>
              </ListContainer>

              <ListContainer>
                <HeaderDiv>Last Update</HeaderDiv>
                <ContentDiv>  {row?.updated_at && format(new Date(row?.updated_at), 'dd-MMM-yyyy hh:mm')} </ContentDiv>
              </ListContainer>

              <ListContainer>
                <HeaderDiv>View Stored Progress</HeaderDiv>
                <ContentDiv>
                <EvenlySpaced> {row?.stored_response ?
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => ShowProgress(row?.id)}
                      color='violet' name='edit' />
                    : "No response"}
                   {row?.stored_response && <><div>{' ## '}</div>
                    <div>Total - {row?.stored_response?.high_answer}</div> <div>{' ## '}</div>
                    <div>Curr - {row?.stored_response?.current_answer} </div> 
              
                    {/* current_question */}
                    
                   </>}  </EvenlySpaced>

                </ContentDiv>
              </ListContainer>


            </RowData>
          </Container>
          <Container>
            <RowData>
              <ListContainer>
                <HeaderDiv>Scheduled Date</HeaderDiv>
                <ContentDiv>
                  {row?.scheduled_at && format(new Date(row?.scheduled_at), 'dd-MMM-yyyy hh:mm')}
                </ContentDiv>
              </ListContainer>

              <ListContainer>
                <HeaderDiv>Valid Upto</HeaderDiv>
                <ContentDiv>
                  {row?.valid_upto && format(new Date(row?.valid_upto), 'dd-MMM-yyyy hh:mm')}
                </ContentDiv>
              </ListContainer>

              <ListContainer>
                <HeaderDiv>Employee</HeaderDiv>
                <ContentDiv>
                  {row?.employee}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Completed Date</HeaderDiv>
                <ContentDiv>
                  {row?.deleted_on && format(new Date(row?.deleted_on), 'dd-MMM-yyyy hh:mm')}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Cancelled</HeaderDiv>
                <ContentDiv>
                  <TypesAndStatus serviceUsed={row?.failed} />
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>View Response</HeaderDiv>
                <ContentDiv>
                  {row?.to_delete ?
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => ShowResponse(row?.token)}
                      color='violet' name='closed captioning' />
                    : "No response"
                  }

                </ContentDiv>
              </ListContainer>
            </RowData>
          </Container>
          <Container>
            <RowData>


            </RowData>
          </Container>
        </Card.Content>
      </Card>
    {/* token response */}
      <MoreDetails
        openPortal={openPortal}
        setOpenPortal={setOpenPortal}
        briefToken={briefToken}
      />

      {/* stored response */}
      <MoreProgress 
        openProgress={openProgress}
        setOpenProgress={setOpenProgress}
        briefToken={briefToken}
      />
    </>
  );
};

// RowCard.propTypes = {
//   row: PropTypes.object.isRequired,
// };

export default RowCard;

function TypesAndStatus({ serviceUsed }) {

  return (
    <>
      {serviceUsed && serviceUsed ?
        <Icon color='green' name='checkmark' size='small' />
        :
        <Icon color='red' name='close' size='small' />
      }
    </>
  )
}

const MoreDetails = ({ openPortal, setOpenPortal, briefToken }) => {
  return (<Modal
    closeIcon
    open={openPortal}
    onClose={() => setOpenPortal(false)}
    onOpen={() => setOpenPortal(true)}
  >
    <Header>Detailed Response</Header>
    <Modal.Content>

      {briefToken &&
        <SurveyResponse briefToken={briefToken} />
      }
    </Modal.Content>
    <Modal.Actions>

      <Button color='purple' onClick={() => setOpenPortal(false)}>
        <Icon name='close' /> Close
      </Button>
    </Modal.Actions>
  </Modal>);
}


const MoreProgress = ({ openProgress, setOpenProgress, briefToken }) => {

  return (<Modal
    closeIcon
    open={openProgress}
    onClose={() => setOpenProgress(false)}
    onOpen={() => setOpenProgress(true)}
  >
    <Header>Detailed Progress</Header>
    <Modal.Content>

      {briefToken &&
        <SurveyProgress briefToken={briefToken} />
      }
    </Modal.Content>
    <Modal.Actions>

      <Button color='purple' onClick={() => setOpenProgress(false)}>
        <Icon name='close' /> Close
      </Button>
    </Modal.Actions>
  </Modal>);
}

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`

const RowData = styled.div`
display: flex;
flex-direction: row;

`

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`

const ContentDiv = styled.div`
  // background-color: #b6701e;

`

const EvenlySpaced = styled.div`
    display: flex;
    justify-content: space-between;
`