import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Environment, SentryDSN_PROD, SentryDSN_STG } from "./WebConfig";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

let Sentr_DSN = null;
if (Environment === "staging") {
  Sentr_DSN = SentryDSN_STG;
}
if (Environment === "production") {
  Sentr_DSN = SentryDSN_PROD;
}
if (Sentr_DSN) {
  Sentry.init({
    dsn: `${Sentr_DSN}`,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
