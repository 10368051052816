import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom';

// import { useNavigate, useLocation } from "react-router-dom";

import List from './List';
import Add from './AddEdit';
import Details from './AddEdit';
import { Header, Grid } from 'semantic-ui-react'

function MarketingRoi() {
  // const location = useLocation()
  // const navigate = useNavigate()
  const [reload, setReload] = useState(null)
  return (
    <>

      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as='h2' style={{ marginTop: "50px" }}>
            Chat Gpt Recommendation
            <Header.Subheader>
            Chat Gpt Recommendation email response
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>

      
      <Routes>
        <Route path="/" element={<List reload={reload} />} />
        <Route path="details/:id" element={<Details setReload={setReload} />} />
        <Route path="create" element={<Add setReload={setReload} />} />
      </Routes>
    </>
  );
}

export default MarketingRoi;
