import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import {Icon} from 'semantic-ui-react'



function makeBoldText(inputText) {
    try{
        // Use a regular expression to find the text before the colon
      const match = inputText.match(/^(.*?):/);
  
      if (match && match[1]) {
        const boldText = `<strong>${match[1]}:</strong>`;
        const replacedText = inputText.replace(/^(.*?):/, boldText);
        return <div dangerouslySetInnerHTML={{ __html: replacedText }} />;
      } else {
        // If there's no match, return the original text
        return inputText;
      }
    }
    catch{
      return inputText
    }
    
  }

const FormatData = ({ setError,editedData,setEditedData,handleSaveTemplate }) => {
  const [editableElements, setEditableElements] = useState(Array(editedData.length).fill(false));

  
  const renderElements = (items) => {
    const elements = [];
    let keyCounter = 0;
  
    items.forEach((item, index) => {
      const isEditable = editableElements[index];
  
      const handleEditClick = () => {
        const updatedEditableElements = [...editableElements];
        updatedEditableElements[index] = true;
        setEditableElements(updatedEditableElements);
      };
  
      const handleSaveClick = () => {
        const updatedEditableElements = [...editableElements];
        updatedEditableElements[index] = false;
        setEditableElements(updatedEditableElements);
      };
  
      const handleTextChange = (event) => {
        const newText = event.target.value;
        const updatedItem = { ...item, text: newText };
        const updatedData = [...editedData];
        updatedData[index] = updatedItem;
        setEditedData(updatedData);
      };
  
      const handleUrlChange = (event) => {
        const newUrl = event.target.value;
        const updatedItem = { ...item, url: newUrl };
        const updatedData = [...editedData];
        updatedData[index] = updatedItem;
        setEditedData(updatedData);
      };
  
      switch (item.type) {
        case 't1':
          if (isEditable) {
            elements.push(
              <T1 key={keyCounter++}>
                <Input
                  type="text"
                  value={item.text}
                  onChange={handleTextChange}
                />
                <button onClick={handleSaveClick}>Save</button>
              </T1>
            );
          } else {
            elements.push(
              <T1 key={keyCounter++}>
                {item.text}
                <Icon name="pencil" style={{fontSize:12}} onClick={handleEditClick}/>
              </T1>
            );
          }
          break;
        case 't2':
          if (isEditable) {
            elements.push(
              <T2 key={keyCounter++}>
                <Input
                  type="text"
                  value={item.text}
                  onChange={handleTextChange}
                />
                <button onClick={handleSaveClick}>Save</button>
              </T2>
            );
          } else {
            elements.push(
              <T2 key={keyCounter++}>
                {item.text}
                <Icon name="pencil" style={{fontSize:12}} onClick={handleEditClick}/>
              </T2>
            );
          }
          break;
        case 't3':
          if (isEditable) {
            elements.push(
              <T3 key={keyCounter++}>
                <Input
                  type="text"
                  value={item.text}
                  onChange={handleTextChange}
                />
                <button onClick={handleSaveClick}>Save</button>
              </T3>
            );
          } else {
            elements.push(
              <T3 key={keyCounter++}>
                {item.text}
                <Icon name="pencil" style={{fontSize:12}} onClick={handleEditClick}/>
              </T3>
            );
          }
          break;
        case 'p':
          if (isEditable) {
            elements.push(
              <P key={keyCounter++}>
                <TextArea value={item.text} onChange={handleTextChange} />
                <button onClick={handleSaveClick}>Save</button>
              </P>
            );
          } else {
            elements.push(
              <P key={keyCounter++}>
                {item.text}
                <Icon name="pencil" style={{fontSize:12}} onClick={handleEditClick}/>
              </P>
            );
          }
          break;
        case 'l':
          if (isEditable) {
            elements.push(
              <LINK key={keyCounter++}>
                <Input
                  type="text"
                  value={item.text}
                  onChange={handleTextChange}
                />
                <Input
                  type="text"
                  value={item.url}
                  onChange={handleUrlChange}
                />
                <button onClick={handleSaveClick}>Save</button>
              </LINK>
            );
          } else {
            elements.push(
              <LINK key={keyCounter++}>
                {item.text}
                <a target='_blank' href={item.url}>{item.link_text}</a>
                <Icon name="pencil" style={{fontSize:12}} onClick={handleEditClick}/>
              </LINK>
            );
          }
          break;
          case 'b':
            if (isEditable) {
              // Handle editing of 'b' type
              // You can provide input fields or text areas for editing bullet points.
              elements.push(
                <div key={keyCounter++}>
                  <TextArea value={item.text} onChange={handleTextChange} />
                  <button onClick={handleSaveClick}>Save</button>
                </div>
              );
            } else {
              // Preserve the original handling for 'b' type
              // Check if the previous element is not a list, start a new list
              if (!elements.length || elements[elements.length - 1].type !== 'ul') {
                elements.push(
                  <ul key={keyCounter++}>{[
                    <LI key={keyCounter++}>{item.text}</LI>
                  ]}</ul>
                );
              } else {
                // If the previous element is a list, add the bullet point to it
                let lastElement = elements.pop();
                lastElement = React.cloneElement(lastElement, {
                  children: [...lastElement.props.children, <LI key={keyCounter++}>{item.text}</LI>]
                });
                elements.push(lastElement);
              }
              elements.push( <Icon name="pencil" style={{fontSize:12}} onClick={handleEditClick}/>);
            }
            break;
        default:
          break;
      }
    });
  
    return elements;
  };
   
    try {
      return <ContentContainer>
          {renderElements(editedData)}          
          </ContentContainer>;
    } catch (error) {
        return setError(true)
    }
  };


export default FormatData

const ContentContainer = styled.div`
  font-family: 'Raleway';
  width:60%;
  padding:20px;
`

const T1 = styled.div`
    font-size:26px;
    font-weight:400;
    margin-bottom:70px;
    text-align:center;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:20px;
    line-height:1.4;
`

const T3 = styled.div`
    font-size:14px;
    font-weight:600;
    margin-bottom:10px;
    line-height:1.4;
`

const P = styled.div`
    margin-bottom: 20px;
    line-height:1.4;
`

const LI = styled.li`
    margin-bottom:10px;
    line-height:1.4;
`
const BackButton = styled.div`
    font-size:12px;
    font-weight:400;
    margin-bottom:10px;
    cursor:pointer;
    display:flex;
    align-items:center;
    margin-bottom:10px;
    margin-left:-10px;
    margin-top:-10px;
    font-family:'Raleway';
`

const LINK = styled.div`
    margin-bottom: 20px;
    margin-top:30px;
`

const TextArea = styled.textarea`
  width: 100%;
  height: 200px;
`

const Input = styled.input`
  width: 100%;
`
