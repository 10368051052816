import styled, { keyframes } from 'styled-components';
import React from "react";
import make_call from './Request'
import {embedding_call} from './Request'
import SelectItem from './SelectItem'
import {Dropdown} from 'semantic-ui-react'
import Preview from './Preview';
import urlConfig from '../WebConfig';


const JourneyPrompts = () =>{
    const [prompt,setPrompt] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [message,setMessage] = React.useState([])
    const [message2,setMessage2] = React.useState([])
    const [message3,setMessage3] = React.useState([])
    const [message4,setMessage4] = React.useState('')
    const [message5,setMessage5] = React.useState('')
    const axios = require('axios');

    const [loading,setLoading] = React.useState(false)
    const [selected, setSelected] = React.useState(null)
    const [selected2, setSelected2] = React.useState(null)
    const [selected3, setSelected3] = React.useState(null)
    const [number, setNumber] = React.useState(1)

    const [savedPrompt,setSavedPrompt] = React.useState(defaultPrompt)
    const [savedPrompt2,setSavedPrompt2] = React.useState(defaultPrompt2)
    const [savedPrompt3,setSavedPrompt3] = React.useState(defaultPrompt3)
    const [savedPrompt4,setSavedPrompt4] = React.useState(defaultPrompt4)
    const [savedPrompt5,setSavedPrompt5] = React.useState(defaultPrompt5)

    const [step,setStep] = React.useState(0)

    const [jsonData, setJsonData] = React.useState(null);
    const token = localStorage.getItem("token");

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${urlConfig.appapi}/v1/read-file/?filename=knowledge_base_V3.json`,
        headers: { 
          'Authorization': 'Token '+token,
        }
      };


    React.useEffect(() => {
        axios.request(config)
            .then((response) => {
                setJsonData(response.data);
            })
            .catch((error) => {
            console.log(error);
            });
    }, []);


    const make_gpt_call = async () =>{

        if(step==0 && selected!==null){
            setSelected(null)
            setMessage2([])
            setMessage3([])

        }
        if(step==1 && selected2!==null){
            setSelected2(null)
            setMessage3([])
        }

        if(step==1){
            if(selected===null){
                return
            }
        }
        if(step==2){
            if(selected===null){
                return
            }
        }

        setLoading(true)
        async function someFunction(gpt_text) {

            const message = await make_call(gpt_text);
            if(step==0){
                setMessage(splitBullets(message));
            }
            if(step==1){
                setMessage2(splitBullets(message));
            }
            if(step==2){
                setMessage3(splitBullets(message));
            }
            if(step==3){
                setMessage4(message)
            }
            if(step==4){
                setMessage5(message)
            }
            setLoading(false)
          }

          let prompt = await generatePrompt()


          someFunction(prompt)
    }

    const getPrompt = () =>{
        if(step==0){
            return savedPrompt
        }
        if(step==1){
            return savedPrompt2
        }
        if(step==2){
            return savedPrompt3
        }
        if(step==3){
            return savedPrompt4
        }
        if(step==4){
            return savedPrompt5
        }
    }

    const setText = (text) =>{
        if(step==0){
            setSavedPrompt(text)
        }
        if(step==1){
            setSavedPrompt2(text)
        }
        if(step==2){
            setSavedPrompt3(text)
        }
        if(step==3){
            setSavedPrompt4(text)
        }
        if(step==4){
            setSavedPrompt5(text)
        }
    }

    const generatePrompt = async () =>{
        let new_prompt
            if(step==0){
                new_prompt = replaceFeedbackWithVariable(savedPrompt,text) 
                return new_prompt
            }
            // if(step==1 && selected!==null){
            //     new_prompt = replaceFeedbackWithVariable(savedPrompt2,text)
            //     new_prompt = replaceFeedbackWithNeed(new_prompt,message[selected])
            //     new_prompt = await addResearch(new_prompt,message[selected],number,jsonData)
            //     return new_prompt
            // }
            if(step==2 && selected!==null){
                new_prompt = replaceFeedbackWithNeed(savedPrompt3,message[selected])
                new_prompt = await addResearch(new_prompt,message[selected],number,jsonData)
                return new_prompt
            }
            if(step==3 || step==4 ){
                let prompt_text = step==3?savedPrompt4:savedPrompt5
                new_prompt = replaceFeedbackWithAction(prompt_text,message3[selected3])
                new_prompt = await addResearch(new_prompt,message3[selected3],number,jsonData)
                return new_prompt
            }

        return new_prompt
    }

    const openPreview = async () =>{
        let text = await generatePrompt()
        console.log(text)
        setPrompt(text)
        setOpen(true)

    }


    


    return (

        <Container>
            <div>
            <TextArea 
            value={getPrompt()}
            onChange={(e)=>setText(e.target.value)}
            />
            <div style={{display:'flex'}}>
            <Button onClick={()=>make_gpt_call()}>Generate {step==0?'Needs':step==1?'Objectives':step==2?"Action":step==3?"Context":'CTA'}</Button>
            <Button onClick={()=>setStep(step>0? step==2? 0:step-1:0)}>Previous Prompt</Button>
            <Button onClick={()=>setStep(step==0?2:step+1)}>Next Prompt</Button>
            <Button onClick={()=>setStep(3)}>Context Prompt</Button>
            <Button onClick={()=>setStep(4)}>CTA Prompt</Button>
            </div>
            <Button onClick={()=>openPreview()}>Preview prompt</Button>
            <Preview 
            open={open}
            setOpen={setOpen}
            text={prompt}
                />
            <Dropdown
            placeholder='Select # of research snippets'
            fluid
            selection
            options={[
                { key: 1, text: '1', value: 1 },
                { key: 2, text: '2', value: 2 },
                { key: 3, text: '3', value: 3 },
                { key: 4, text: '4', value: 4 },
                { key: 5, text: '5', value: 5 },
                { key: 6, text: '6', value: 6 },
                { key: 7, text: '7', value: 7 },
                { key: 8, text: '8', value: 8 },
                { key: 9, text: '9', value: 9 },
                { key: 10, text: '10', value: 10 },
            ]}
            value={number}
            onChange={(e,data)=>setNumber(data.value)}
            />
            </div>

            <Half>
                {loading?'loading...':''}

                <Title>Needs</Title>
                {message.map((item,index)=>{
                    return (
                        <SelectItem item={item} selected={selected==index} setSelected={()=>setSelected(index)} hide={step!=0}/>
                    )   
                })}
            {/* <Title>Objective</Title>
            {message2.map((item,index)=>{
                                    return (
                                        <SelectItem item={item} selected={selected2==index} setSelected={()=>setSelected2(index)} hide={step!=1}/>
                                    )   
                                })} */}

            <Title>Action</Title>
            {message3.map((item,index)=>{
                                    return (
                                        <SelectItem item={item}  selected={selected3==index} setSelected={()=>setSelected3(index)} hide={step!=2}/>
                                    )   
                                })}    

            <Title>Context</Title>   
             {message4}
            <Title>CTA</Title>        
                {message5}
            </Half>
           

        </Container>
    )
}



export default JourneyPrompts


const Container = styled.div`
    margin-top:30px;
    display:flex;
    font-family:'Raleway';
`

const Button = styled.button`
    cursor:pointer;
`

const TextArea = styled.textarea`
    width:600px;
    height:600px;
    font-family:'Raleway';
    padding:5px 10px;
    line-height:1.6;
`

const Half = styled.div`
    width:600px;
    padding:20px;
`

const Title = styled.div`
    font-size:20px;
    font-weight:bold;
    margin-top:20px;
`

function replaceFeedbackWithVariable(inputString, variable) {
    return inputString.replace('{feedback}', variable);
  }

  function replaceFeedbackWithObjective(inputString, variable) {
    return inputString.replace('{objective}', variable);
  }

  function replaceFeedbackWithAction(inputString, variable) {
    return inputString.replace('{action}', variable);
  }

  function replaceFeedbackWithNeed(inputString, variable) {
    
    return inputString.replace('{need}', variable).replace();
  }

async function getEmbeddings(text){
    const embeddings = await embedding_call(text);
    return embeddings
}

function getResearch(kb_result){
    let research = "RESEARCH SNIPPETS: \n\n"
    kb_result.map((i)=>{
        research+= i.text + "\n\n"
    
    })
   
    research+= "###END of RESEARCH \n\n"
    return research
}
    

async function addResearch(inputString, variable,number,jsonData) {
        let queryVector = await getEmbeddings(variable);
        let results = vectorSearch(queryVector, jsonData);
        let research = getResearch(results.slice(0, number))
        return inputString.replace('{research}', research).replace();

    }


function splitBullets(s) {
    // Split based on numbered bullets and filter out empty strings
    return s.split(/\d+\.\s/).filter(item => item.trim() !== "");
  }


  function cosineSimilarity(vector1, vector2) {
    let dotProduct = 0;
    let mag1 = 0;
    let mag2 = 0;
    
    for (let i = 0; i < vector1.length; i++) {
        dotProduct += vector1[i] * vector2[i];
        mag1 += vector1[i] ** 2;
        mag2 += vector2[i] ** 2;
    }
    
    mag1 = Math.sqrt(mag1);
    mag2 = Math.sqrt(mag2);
    
    return dotProduct / (mag1 * mag2);
}


// Function to perform vector search based on cosine similarity
function vectorSearch(queryVector, data) {
    let results = [];
    
    for (let item of data) {
        let similarity = cosineSimilarity(queryVector, item.vector);
        results.push({ id: item.id, similarity: similarity,text:item.text });
    }
    
    // Sort results based on similarity (highest to lowest)
    results.sort((a, b) => b.similarity - a.similarity);
    
    return results;
}



const defaultPrompt =`
The following are survey questions and responses from a culture survey:
{feedback}
Based on the feedback, generate a list of 1-3 gaps for the organization, depending on unique issues.
The gaps should focus on specific concepts, themes, or ideas that the organization can address or take action on based on this feedback.
The gaps should represent clusters of consensus opinion, and not one-off opinions. Use language, context, and ideas expressed in the feedback itself.
Here is an example of a needs assessment list:
Example:
Current lack of transparent and timely communication within and between franchises and at all organizational levels (from executive to employees). Leads to confusion and unalignment within comm. department.
Reactive and unclear approach to everchanging objectives, initiatives, and performance goals and expectations. Causes subcultures and the development of silos between teams and/or franchises.
No team-based performance goals to foster collaboration, celebrate short-term wins, and hold people accountable within the commercial department. Creates little shared ownership and understanding of how to operationally grow in conjunction with Resulta’s overall strategic vision.
No personal and tangible OKRs (except generating more FTDs) that are tied to clear learning outcomes relevant to the commercial department’s goals. Produces feelings of disconnect and isolation.
###END of Example
Generate a needs assessment with at most 3 needs. Format each need as follows:
1. Need title : Need description
\n\n
Needs assessment:
`

const defaultPrompt2 =`
For context, the following are survey questions and responses from a culture survey:
{feedback}
From this text, we have identified the following culture need:
{need}
Based on the identified need, and keeping the original context in mind, generate a list of 3 objectives for the leader receiving this information.
The objectives should be clear outcome goals related to addressing the specific need. Please number each objective as follows:
1. 2. 3.
Objectives:
`

const defaultPrompt3 =`
The following is an identified culture need in an organization:
{need}

Based on the identified objective, generate 3 specific actions that a manager
can take. Each action should be 2-3 sentences long and should be able to fit on a sticky note. 
The action is something that the leader can directly implement themselves. It should be a behavior
or process they can immediately implement with all the information in the action. It should specifically
address the objective in a way that is practical and easy to implement. Focus on day-to-day behaviors
or habits that can be easily implemented and actioned immediately. Not on things outside the leader's control. Don't be generic.

Here's an example:
Make it clear that differing opinions are not only accepted but valued. Ask employees for their perspectives on specific issues
and genuinely consider their input. For instance, you could say, 'I'd like to hear your thoughts on this project.
Do you see any potential issues or improvements we should consider?

Please number each action as follows: 1. 2. 3.
Actions:
`

const defaultPrompt4 =`
The following is an identified action to address a culture need in an organization by a leader:
{action}

The "what" and the "why" of the selected action (context) - 
Explain what the action is in the context of the identified need and
why it is important and/or something that the manager should considering
acting on immediately. 2 - 3 sentences.

Context:
`

const defaultPrompt5 =`
The following is an identified action to address a culture need in an organization by a leader:
{action}

The "when" and the "how" - the "when" could be the situation/scenario and the
how is the probe or guiding question that the leader can use related to the
identified need/factor. Based on the action generate a call to action which 
covers these two aspects. 2 - 3 sentences.

Call to action:
`

const text = `### Feedback

Question:We live and breathe our organizational values each day.
USER FEEDBACK:
everyday job is not directly connected to the values
Question:We live and breathe our organizational values each day.
USER FEEDBACK:
As we are currently in between defining the new Adcash value set, I wouldn't say we live and breathe it daily. Our previous values are relevant to how we operate, but not all are relevant anymore. We operate very much according to the group's values, which are more similar to how Adcash is today.
Question:We live and breathe our organizational values each day.
USER FEEDBACK:
We are and have always tried to be the best platform in the highly-competitive market, always innovating and learning to stay on top of our game. In addition, what sets our company apart from many others are the relations, transparency, and connections we have with each other. We know that the true value of the company is the people. 
Question:We live and breathe our organizational values each day.
USER FEEDBACK:
Teamwork - great teams with team leaders, always ready to help each other.
Democracy, Openness, Trust
Question:We live and breathe our organizational values each day.
USER FEEDBACK:
The word "values" made me immediately think on the group values that were presented to us recently. While I and most of the people I talked to fully agree with all of them, it's a bit too early to say that " we live and breathe them" the way they are worded. That would be exaggerated. 
Question:We live and breathe our organizational values each day.
USER FEEDBACK:
Not by selling us big beautiful words, but by actions from the group management. Let us all mix by traveling (not only the C level traveling) but everyone who wants to go and learn from another project (not by paying their own money). Staying competitive does not only mean the employees' performance, but also the competitive salary in our field + the general employees conditions compared to other offers out there in each country.  
Question:Our organizational values guide our behaviours.
USER FEEDBACK:
I put this at 6 because for the most part we were in line with the values - it was our natural way of doing things already. The values just summed it up.
Question:Our organizational values guide our behaviours.
USER FEEDBACK:
I'd say we have people first working environment that cares to listen, there's always a place for new ideas and opinions which are not condemned or dismissed for a ignorant reasons. This is a healthy environment for innovation and growth overall.
Question:There are concrete ways to carry out our shared organizational values.
USER FEEDBACK:
-
Question:There are concrete ways to carry out our shared organizational values.
USER FEEDBACK:
There aren't CONCRETE ways, it's just a side-product of our daily work.
Question:There are concrete ways to carry out our shared organizational values.
USER FEEDBACK:
The job tasks are mean't to help us fulfil the organizational values
Question:We live and breathe our organizational values each day.
USER FEEDBACK:
I see big slogans of our values but I don't need it to be pointed that much.
Question:We live and breathe our organizational values each day.
USER FEEDBACK:
Make them visible as big slogans once and then acessable if needed.
Question:We live and breathe our organizational values each day.
USER FEEDBACK:
If by this we mean that we breath the mission and vision or we have them present every day, then not really.
Question:We live and breathe our organizational values each day.
USER FEEDBACK:
The mission and vision themselves
Question:There are concrete ways to carry out our shared organizational values.
USER FEEDBACK:
No clear understanding of concrete ways to carry out our values
Question:There are concrete ways to carry out our shared organizational values.
USER FEEDBACK:
Trust more; be less afraid/concerned about proposed changes; Define where and how we could be competitive (not as a company, but as individuals)
Question:We live and breathe our organizational values each day.
USER FEEDBACK:
I feel like it is unclear what our organizational values are. 
Follow up question:
What specific areas or aspects of our organizational values are unclear to you?
USER FEEDBACK:
It is unclear how our day to day work aligns with the values

Question:We live and breathe our organizational values each day.
USER FEEDBACK:
Doesnâ€™t have an impact at all
Follow up question:
How can we create more impact with our organizational values in our daily actions?
USER FEEDBACK:
It is not a problem

Question:We live and breathe our organizational values each day.
USER FEEDBACK:
The challenge here is I couldn't recollect us ever discussing what these values are. I don't know what they are, so can't say whether we "live or breathe" them.
Follow up question:
What steps can the organization take to ensure that employees are aware of and understand the organizational values?
USER FEEDBACK:
Might start by making them known/clear.

Question:There are concrete ways to carry out our shared organizational values.
USER FEEDBACK:
We should clearly define our organizational values. 
Follow up question:
How can we effectively define our organizational values?
USER FEEDBACK:
Have a joint conversation around them to help establish what they are. Partly from the results of this survey, partly through discussions, workshops etc.  

Question:We live and breathe our organizational values each day.
USER FEEDBACK:
feel that we might not truly live and breathe the organizational values because I personally lack clear awareness of what they are. While I have a general understanding based on my daily interactions with colleagues, I don't have access to a document or information outlining the organization's values and policies. It would be beneficial for me to have a more concrete reference to align my actions and decisions with these values.
Follow up question:
What steps could the organization take to provide employees with clear access to the organization's values and policies?
USER FEEDBACK:
Documentation and communication, employee handbook, training sessions, visuals, website where employees can access important documents

Question:There are concrete ways to carry out our shared organizational values.
USER FEEDBACK:
Missing info about company
Follow up question:
What specific information about the company is missing that would help address the issue?
USER FEEDBACK:
Vision, Mision

Question:We live and breathe our organizational values each day.
USER FEEDBACK:
I have not been communicated what the mission, vision nor values of the company are, therefore I can't live by what I don't know.
Follow up question:
What steps can the company take to effectively communicate its mission, vision, and values to all employees?
USER FEEDBACK:
Begin by defining one, and if they already did so, then communicate it.

Question:There are concrete ways to carry out our shared organizational values.
USER FEEDBACK:
When a company is big, there are different minds, cultures, philosophies, and religions, and it is amazing how the company is taking care of adding value to get the results that the company is looking for and also with respect for everyone in the company. It is impossible to be perfect on this, and there is always room to improve or adapt, but what I sense in the team there is the perception to work on this as close as perfection. 
Follow up question:
How can the organization further improve and adapt to ensure that all individuals' cultures, philosophies, and religions are respected and valued while carrying out shared organizational values?
USER FEEDBACK:
I consider that the company and the team members are working every day on this with happiness and joy.

Question:We live and breathe our organizational values each day.
USER FEEDBACK:
I think there are different ways to express core values in the company. 
Follow up question:
Can you give examples of how the organization currently expresses its core values and how it could be improved?
USER FEEDBACK:
People first approach, we could share more across organizations 

Question:There are concrete ways to carry out our shared organizational values.
USER FEEDBACK:
I think we generally live by it but we don't actually think about specific ways. It's more like a natural thing at this point
Follow up question:
Can you provide examples of how your team puts the shared organizational values into action?
USER FEEDBACK:


Question:There are concrete ways to carry out our shared organizational values.
USER FEEDBACK:
I considered the question to be at CliQ level. Being such a big organization, the shared values are harder to keep in mind than at a franchise level.
Follow up question:
What specific methods or strategies could be implemented to ensure that shared values are consistently remembered and acted upon throughout the entire organization?
USER FEEDBACK:
Organization-wide communications and activities.

Question:We live and breathe our organizational values each day.
USER FEEDBACK:
I believe each department has their own way of working. Some more organized than others. 
Follow up question:
How can we work towards ensuring consistency in upholding the organization's values across all departments?
USER FEEDBACK:
Ensure each individual has a say on how to better work within their own department. 

Question:We live and breathe our organizational values each day.
USER FEEDBACK:
I don't think we have a clear understanding of what our organizational values are, why we've chosen them, and how we can uphold them in our day to day work.
Follow up question:
Can you provide examples of where you have seen confusion or lack of clarity around the organizational values?
USER FEEDBACK:
The values on the wall don't necessarily match the ones that are important to our business. 

Question:Our organizational values guide our behaviours.
USER FEEDBACK:
N/A
Follow up question:
What specific values do you believe are missing or not being demonstrated by the organization?
USER FEEDBACK:
N/A

Question:Our organizational values guide our behaviours.
USER FEEDBACK:
I'm not entirely sure what the values are so it wouldn't make sense to say that they guide any behaviour I have.
Follow up question:
What specific actions can the organization take to better communicate and reinforce its values so that they guide the behaviors of all employees?
USER FEEDBACK:
What is meant by values? Practical values, ethical values? It all seems a bit high concept that I am not sure really plays into my day to day work so it's hard to suggest anything practical here.

Question:We live and breathe our organizational values each day.
USER FEEDBACK:
I'm not sure I can list all our organizational values. 
Follow up question:
Can you tell me more about the ways in which your organization communicates and reinforces its values to ensure they are understood and practiced by employees on a daily basis?
USER FEEDBACK:
I don't think this is being done. 

Question:There are concrete ways to carry out our shared organizational values.
USER FEEDBACK:
There are no concrete ways to carry out shared org values as far as I can tell. May be its present but its not communicated well.
Follow up question:
Can you provide examples of how the organizational values are not communicated well and suggest ways to improve communication?
USER FEEDBACK:
Periodic communication about the vision and values from the executives and same messages tricked down to the staff from managers would be highly effective in my opinion.

Question:Our organizational values guide our behaviours.
USER FEEDBACK:
There is less town halls and company meetings then when I first started so feel less sure of how our values guide our behaviours,
Follow up question:
What specific actions can the organization take to increase communication and clarity around how our values guide our behaviours?
USER FEEDBACK:
Share company information more transparently.

Question:We live and breathe our organizational values each day.
USER FEEDBACK:
This wasn't about our company or the ELT or management. This was me getting very granular in my thinking on one little thing. It's all good
Follow up question:
Can you provide an example of the specific organizational values that you feel are not being lived and breathed each day in the company?
USER FEEDBACK:
I don't feel any negativity. Have always felt encouraged to be myself and make sure others do too. Any ideas can be shared and talked out

Question:We live and breathe our organizational values each day.
USER FEEDBACK:
If we had more clarity on our organization values for how we work and communicate, I think it would go a long way to improve cross functional collaboration.

It's not that I think we have bad organizational values. I just think that we need to write them down and live them everyday.

Follow up question:
What specific values do you think should be included in the organization's list of values for how they work and communicate?
USER FEEDBACK:
Build Products iteratively
Communicate Constantly
Share Knowledge
Work Cross-functionally

Question:We live and breathe our organizational values each day.
USER FEEDBACK:
It hasn't really impacted me personally. As I mentioned, I am trusting this work is being, or will be, done.  I have heard from colleagues that they experience cognitive dissonance between our values and culture.  
Follow up question:
Can you share some specific examples of how you and your colleagues experience the cognitive dissonance between the organizational values and culture?
USER FEEDBACK:
Again, I don't personally experience this. I have been told that the organization is "people first" and that this does not align with the current culture.  I personally choose to believe that the organization is striving for a people first culture.  That is why I came here...to be a part of that.

Question:Our organizational values guide our behaviours.
USER FEEDBACK:
I don't know what our organizational values are? Do we have these formalized? 
Follow up question:
How can the organizational values be more clearly communicated and formalized for all employees to understand and follow?
USER FEEDBACK:
First step is alignment from the executive on what those values are. The onus here being on the executive to commit to values that we are actually expected to use to guide behaviour and not just values that we let our creative services team come up with, because they sound nice on LinkedIn. 

Who do we want to be? Decide that and then communicate it to the organization and commit to leading by example. If the executive (at all levels) aren't committed to these values through action, the exercise will be futile. 

Question:Our organizational values guide our behaviours.
USER FEEDBACK:
.
Follow up question:

USER FEEDBACK:


Question:We live and breathe our organizational values each day.
USER FEEDBACK:
Values seem more like a front to draw employees then something nutured in the company. Saying all the right things but no action.
Follow up question:
Can you provide specific examples of when the company has failed to uphold its stated values?
USER FEEDBACK:
There's diversity in hiring but no cultural shift. Promotion of drinking culture is inaccessible/unappealing to most people yet it's the only way to get serious facetime with any high-level people in the company.

Question:There are concrete ways to carry out our shared organizational values.
USER FEEDBACK:
The organizational values don't often seem clear, and I don't see a lot of practical connection between them and the everyday work process.
Follow up question:
Can you provide examples of how the organizational values could be better integrated into the daily work process?
USER FEEDBACK:
N/a

Question:There are concrete ways to carry out our shared organizational values.
USER FEEDBACK:
Similar to vision, the values of the company to me seem to be fairly standard. If they aren't standard than we would need to know what they are in specific. If they are then the ways that we complete them are just showing up and do your work. This isn't really a concrete way, however, it's just "do your job"
Follow up question:
"How can we create more concrete ways to put our shared values into action?"
USER FEEDBACK:
The issue isn't in having methods, we need to know what values you intend to highlight. The methodology will vary depending on the specific value.

### END of FEEDBACK

`;