export const description_sample =
  "This Data Story template is a defined structure for a data story. It is a guide to help you create a data story that is clear, concise, and easy to understand. The template is designed to help you communicate the key insights and findings from your data analysis in a way that is easy to understand and visually appealing.";


  export const template_versions = [
    {
      key: "v1 - Default",
      text: "v1 - Default",
      value: 1,
    },
    {
      key: "v2",
      text: "v2",
      value: 2,
    },
    {
      key: "v3",
      text: "v3",
      value: 3,
    },
];

  export const template_options = [
    {
      key: "Title",
      text: "Title",
      value: 0,
    },
    {
      key: "Secondary Title",
      text: "Secondary Title",
      value: 1,
    },
    {
      key: "Paragraph",
      text: "Paragraph",
      value: 2,
    },
    {
      key: "Bullets",
      text: "Bullets",
      value: 3,
    },
    {
      key: "Data Table",
      text: "Data Table",
      value: 4,
    },
    {
      key: "Factor Bar",
      text: "Factor Bar",
      value: 5,
    },
    {
      key: "Tertiary Title",
      text: "Tertiary Title",
      value: 6,
    },
    {
      key: "Numbered List",
      text: "Numbered List",
      value: 7,
    }
  ];

 export const default_template = [
    {
      key: "Title",
      text: "Title",
      value: 0,
      input_text: "Title for the plan, 50 characters max.",
    },
    {
      key: "Paragraph",
      text: "Paragraph",
      value: 2,
      input_text: "A summary of the overall plan details. 3-4 sentences.",
    },
    {
      key: "Secondary Title",
      text: "Secondary Title",
      value: 1,
      input_text: "Title for the first solution",
    },
    {
      key: "Paragraph",
      text: "Paragraph",
      value: 2,
      input_text: "A description of the first solution. 5 sentences long.",
    },
    {
      key: "Bullets",
      text: "Bullets",
      value: 3,
      input_text: [
        "Action step 1 for the solution described above. 2-3 sentences.",
        "Action step 2, adds to step 1  in. 2-3 sentences",
        "Action step 3, adds to step 2. 2-3 sentences.",
      ],
    },
    {
      key: "Secondary Title",
      text: "Secondary Title",
      value: 1,
      input_text: "Title for the 2nd solution",
    },
    {
      key: "Paragraph",
      text: "Paragraph",
      value: 2,
      input_text: "A description of the second solution. 5 sentences long.",
    },
    {
      key: "Bullets",
      text: "Bullets",
      value: 3,
      input_text: [
        "Action step 1 for the solution described above. 3-4 sentences.",
        "Action step 2, build on step 1  in. 3-4 sentences",
        "Action step 3, builds on step 2. 3-4 sentences.",
      ],
    },
  ];