import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import { Table, Icon } from 'semantic-ui-react'

import { format } from 'date-fns'


const GptWebChatRow = ({ rows }) => {
    const navigate = useNavigate()

    return (

        <>
            <Table>
                <Table.Header>
                <Table.Row>
                <Table.HeaderCell width={1}>Id</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Organization</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Response</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Type Of</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Created</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Updated</Table.HeaderCell>
                    
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows && rows.map((row, i) => {

                        return (<Table.Row key={i}>
                            <Table.Cell>{row?.id}</Table.Cell>
                            <Table.Cell>{row?.organization_name}</Table.Cell>
                            <Table.Cell>{row?.status_text}</Table.Cell>
                            <TableCell noResponse={isEmpty(row?.feedback)?.rows}>
                                {isEmpty(row?.feedback)?.rows ? `${row?.feedback?.length} Responses` : "No Response"}
                            </TableCell>
                            <Table.Cell>{row?.type_of_text}</Table.Cell>
                            <Table.Cell>
                                {row?.created_at && format(new Date(row?.created_at), 'dd-MMM-yyyy hh:mm')}
                            </Table.Cell>
                            <Table.Cell>
                                {row?.created_at && format(new Date(row?.updated_at), 'dd-MMM-yyyy hh:mm')}
                            </Table.Cell>
                            
                            <Table.Cell>
                            <Icon style={{cursor: "pointer"}} 
                            onClick={() => navigate(`/app/ml-operations/details/${row?.id}`)}
                            name='angle double right' />

                            </Table.Cell>
                        </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    )
};

GptWebChatRow.propTypes = {
    rows: PropTypes.array.isRequired,
};

export default GptWebChatRow;

function isEmpty(item) {
    const response = {
        error: false,
        rows: true
    }
    // console.log(item)
    if (item === null) {
        response.rows = false
        return response;
    }
    if (typeof item === 'object') {
        if (Object.keys(item).length === 0){
            response.rows = false
            return response;
        }
    }
    if (Array.isArray(item)) {
        if (Object.keys(item).length === 0){
            response.rows = false
            return response;
        }
        item.forEach(item => {
            if (item?.error ) {
                response.error = true
                return response;
            } 
            if (item?.server?.error ) {
                response.error = true
                return response;
            }
        });
    }
    return response; // if the item is neither an object nor an array
}


const TableCell = styled.td`
    background-color: ${props => props.noResponse ? '#A8D5B7' : '#F0A8A8'}; // muted green and muted red
`;