import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Message } from "semantic-ui-react";
import DateTimePicker from "react-datetime-picker";
import PropTypes from "prop-types";
import Select from "react-select";

import { EMAIL_SURVEY_TYPES, SURVEY_TYPE } from "data/AdminData";
import { CommonTimezones } from "data/timezone";

import {
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_SURVEY_QUESTIONS,
  SG_ADD_DEBRIEF_SCHEDULE,
  SG_GET_ALL_SURVEY_QUESTION_SELECT,
  PULL_ORGANIZATION,
} from "constants/actions";

const AddSchedule = ({ organizationId, DelayedLoad, isMulti=true, demo_survey=false, surveyStatus=21, allQuestions=false }) => {
  const dispatch = useDispatch();

  const [organizationDetails, setOrganizationDetails] = useState(null);
  const [dateValue, setDateValue] = useState(new Date());
  // const [briefId, setBriefId] = useState(false)
  // const [organization, setOrganization] = useState(false);
  // const [briefType, setBriefType] = useState(false);
  // const [surveyInfo, setSurveyInfo] = useState(false)

  const [surveyType, setSurveyType] = useState(false);
  const [surveyQuestions, setSurveyQuestions] = useState(false);
  const [pristine, setPristine] = useState(true);

  const [servicesEnabled, setServicesEnabled] = useState([]);

  const [recheckQuestionList, setRecheckQuestionList] = useState(false);

  const [selectedPrimary, setSelectedPrimary] = useState([]);
  const [primaryCategory, setPrimaryCategory] = useState([]);
  const [selectedNoOfHours, setSelectedNoOfHours] = useState([]);
  const [surveyStructure, setSurveyStructure] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  const [primarySecondaryName, setPrimarySecondaryName] = useState(false);

  // const [cultureAuditQues, setCultureAuditQues] = useState(0);

  const [surveySeleStructure, setSurveySeleStructure] = useState(false);
  const [surveySeleQuestion, setSurveySeleQuestion] = useState(false);

  const [timeZone, setTimeZone] = useState({
    value: "America/Halifax",
    label: "America/Halifax",
    text: "America/Halifax",
  });

  const { get_questions, get_organization, get_survey_structure } = useSelector(
    (state) => ({
      get_questions: state.surveyquestion,
      get_organization: state.organizations,
      get_survey_structure: state.surveystructure?.survey_structure,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (organizationId) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: organizationId },
      });
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (organizationId) {
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${organizationId}`,
      });
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    setOrganizationDetails(get_organization[organizationId]);
  }, [get_organization, organizationId]);

  useEffect(() => {
    setSurveyStructure(get_survey_structure?.results);
  }, [get_survey_structure]);

  useEffect(() => {
    if (allQuestions) {
      setSurveyQuestions(get_questions?.survey_question_all_select);
    } else {
      setSurveyQuestions(get_questions?.survey_question?.results);
    }
    
  }, [get_questions, allQuestions]);

  useEffect(() => {
    if (get_survey_structure?.results) {
      setPrimarySecondaryName({
        secondary: get_survey_structure?.results?.[0]?.categories?.find(
          (f) => f.priority === "secondary"
        )?.name,
        primary: get_survey_structure?.results?.[0]?.categories?.find(
          (f) => f.priority === "primary"
        )?.name,
      });
    }
  }, [get_survey_structure]);


  useEffect(() => {
    const _ServiceList = [];
    if (organizationDetails) {
      EMAIL_SURVEY_TYPES.map((sr) => {
        const Found = organizationDetails?.services_enabled.filter(
          (od) => od.name === sr.label
        )?.[0];
        if (Found?.id > 2 && Found.enabled === true) {
          _ServiceList.push({
            value: sr.value,
            label: Found.name,
            sortOrder: Found.question_sort_order,
          });
        }
        return null;
      });
    }
    setServicesEnabled(_ServiceList);
  }, [organizationDetails]);

  useEffect(() => {
    if (organizationDetails) {
      setTimeZone({
        value: organizationDetails?.time_zone,
        label: organizationDetails?.time_zone,
        text: organizationDetails?.time_zone,
      });
    }
  }, [organizationDetails]);

  function UpdateTimezone(e) {
    setTimeZone({
      value: e.value,
      label: e.value,
      text: e.value,
    });
  }

  useEffect(() => {
    const _primaryList = [];
    const _groupList = [];
    if (get_survey_structure?.results?.[0]?.categories?.length > 0) {
      get_survey_structure?.results?.[0]?.categories.map((st) => {
        if (st?.priority === "primary") {
          // no subfactors
          st.options.map((str) => {
            if (str?.subfactors?.length > 0) {
              _groupList.push(str?.name);
              str?.subfactors.map((opf) => {
                _primaryList.push({
                  value: opf.name,
                  name: opf?.name,
                  name_id: opf?.id,
                  label: opf?.name,
                  group: str?.name,
                  mainGroup: st?.name,
                  type_of: st?.priority,
                  subtitle: st?.subtitle,
                  priority: st?.priority,
                });

                return null;
              });
            } else {
              _primaryList.push({
                value: str?.name,
                label: str?.name,
                name: str?.name,
                name_id: str?.id,
                type_of: st?.priority,
                subtitle: st?.subtitle,
                priority: st?.priority,
              });
            }

            return null;
          });
          return null;
        }
        setPrimaryCategory(_primaryList);
        return null;
      });
    }
  }, [get_survey_structure]);

  const onSubmit = () => {
    if (formErrors.length > 0) {
      setPristine(false);
      return;
    }
    let _multiple_list = []
  if (isMulti) {
    _multiple_list = selectedPrimary.map((sp) => [sp.name, sp.name_id])
  } else {
    _multiple_list.push(selectedPrimary?.name, selectedPrimary?.name_id)
  }
    dispatch({
      type: SG_ADD_DEBRIEF_SCHEDULE,
      payload: {
        user_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        type_of: surveyType.value,
        multiple_list: _multiple_list,
        scheduled_for: dateValue,
        time_zone: timeZone.value,
        close_in_minutes: selectedNoOfHours.value,
        organization: Number(organizationId),
        brief_question_id: surveySeleQuestion?.id,
        survey_structure: surveySeleStructure?.id,
        status: surveyStatus,
        demo_survey: demo_survey,
      },
    });
    // setFetchAgain(Math.random());

    setPristine(true);
    // setAlertMessage({ no: 1, text: "Successfully Added New Schedule" });
    ResetForm();
    DelayedLoad();
  };

  function ResetForm() {
    setSelectedPrimary([]);
    setDateValue(new Date());
    setSelectedNoOfHours([]);
    setSurveySeleQuestion(false);
    setSurveySeleStructure(false);
  }

  // console.log(surveyQuestions)
  // console.log(organizationDetails)
  function GetSurveyQuestion(e) {
    setSurveyType(e);
    if (allQuestions) {
      dispatch({
        type: SG_GET_ALL_SURVEY_QUESTION_SELECT,
        payload: `status=2`,
      });
      return;
    }
    if (e?.sortOrder > 0) {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `status=2&sort_order=${e?.sortOrder}`,
      });
      return;
    }
    const SurveyType = SURVEY_TYPE.filter((sr) => sr?.label === e?.label)?.[0];
    if (SurveyType?.value) {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `status=2&survey_type=${SurveyType?.value}`,
      });
      return;
    }
  }


  // useEffect(() => {
  //   setSurveySeleQuestion(surveyQuestions?.[0]);
  // }, [surveyQuestions]);

  useEffect(() => {
    if (surveyStructure?.length === 1) {
      setSurveySeleStructure(surveyStructure[0]);
    }
  }, [surveyStructure, recheckQuestionList]);

  useEffect(() => {
    if (recheckQuestionList) {
      const timer1 = setTimeout(() => {
        setRecheckQuestionList(false);
      }, 2000);
      return () => clearTimeout(timer1);
    }
  }, [surveySeleQuestion, surveySeleStructure, recheckQuestionList]);

  useEffect(() => {
    const ErrorList = [];
    if (!surveyType?.value) {
      ErrorList.push("Select a Service");
    }
    if (selectedPrimary?.length === 0) {
      ErrorList.push("Select a Sport");
    }
    if (!dateValue) {
      ErrorList.push("Select a Date");
    }
    if (!selectedNoOfHours.value) {
      ErrorList.push("Closing Hours");
    }
    if (!surveySeleQuestion?.id) {
      ErrorList.push("Select a Survey Question");
    }
    if (!surveySeleStructure?.id) {
      ErrorList.push("Select a Select Structure");
    }
    setFormErrors(ErrorList);
  }, [
    surveyType,
    selectedPrimary,
    dateValue,
    selectedNoOfHours,
    surveySeleQuestion,
    surveySeleStructure,
    recheckQuestionList,
  ]);


  return (
    <>
      <Title>Schedule an Audit (Based on Service) Assessment</Title>

      <div>
        {formErrors.length > 0 && !pristine && (
          <Message
            negative
            //   onDismiss={clearErrors}
            header="We need these details!"
            list={formErrors}
          />
        )}

        <FormContainer>
          <Label>Select a Service</Label>
          <Select
            name="ServicesEnabled"
            options={servicesEnabled}
            isClearable
            onChange={GetSurveyQuestion}
            value={surveyType}
            // getOptionLabel={(answers) => answers.name}
            // getOptionValue={(answers) => answers.id}
          />
        </FormContainer>
        <FormContainer>
          <Label>{primarySecondaryName?.primary}</Label>

          <Select
            placeholder={`Select ${primarySecondaryName?.primary}`}
            onChange={(e) => setSelectedPrimary(e)}
            value={selectedPrimary}
            isMulti={isMulti}
            options={primaryCategory}
            // getOptionLabel={(option) => option.name}
            // getOptionValue={(option) => option.role}
          />
        </FormContainer>

        <FormContainer>
          <Label>Time Zone</Label>
          <Select
            placeholder="Select your timezone"
            onChange={(e) => UpdateTimezone(e)}
            value={timeZone}
            options={CommonTimezones}
          />
        </FormContainer>
        <FormContainer>
          <Label>Select a date and time</Label>
          <DateTimePicker onChange={setDateValue} value={dateValue} />
        </FormContainer>
        <FormContainer>
          <Label>After how many hours should the survey close?</Label>
          {/* <Input placeholder='# of hours' /> */}

          <Select
            placeholder="Select No of Hours"
            onChange={(e) => setSelectedNoOfHours(e)}
            value={selectedNoOfHours}
            options={HoursList}
          />
        </FormContainer>
        {/* {!surveySeleQuestion?.id && ( */}
          <FormContainer>
            <Label>Select Survey Question</Label>
            {/* <Input placeholder='# of hours' /> */}

            <Select
              placeholder="Select Question"
              onChange={(e) => setSurveySeleQuestion(e)}
              value={surveySeleQuestion}
              options={surveyQuestions}
              getOptionLabel={(option) => `${option.name} - ${option.sort_order}`}
              getOptionValue={(option) => option.id}
            />
          </FormContainer>
        {/* )} */}
        {/* {surveyStructure?.length !== 1 && ( */}
          <FormContainer>
            <Label>Select Category</Label>
            {/* <Input placeholder='# of hours' /> */}

            <Select
              placeholder="Select Category"
              onChange={(e) => setSurveySeleStructure(e)}
              value={surveySeleStructure}
              options={surveyStructure}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </FormContainer>
        {/* )} */}
      </div>

      <StyledButton
        color={"#2d50e2"}
        disable={formErrors.length > 0 && !pristine}
        onClick={onSubmit}
      >
        Schedule
      </StyledButton>
    </>
  );
};

AddSchedule.propTypes = {
  briefId: PropTypes.number,
  primaryCategory: PropTypes.array,
  setFetchAgain: PropTypes.func,
  trigger: PropTypes.node,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  surveyQuestions: PropTypes.array,
  surveyStructure: PropTypes.array,
  primarySecondaryName: PropTypes.object,
  surveyType: PropTypes.number,
  surveyInfo: PropTypes.object,
};

const HoursList = [
  { value: 30, label: "Half Hour (30 mins) - testing only" },
  { value: 60, label: "One Hour (60 mins) - testing only" },
  { value: 1440, label: "One Day (24 hrs)" },
  { value: 4320, label: "Three Days" },
  { value: 10080, label: "1 Week" },
  { value: 20160, label: "2 Week" },
  { value: 30240, label: "3 Week" },
  { value: 40320, label: "4 Week" },
];

/******** Styles ****************************************************/

const Title = styled.div`
  font-size: 26px;
  font-weight: bold;
  font-family: "Barlow", sans-serif;
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-family: "Barlow", sans-serif;
  font-weight: bold;
`;

const FormContainer = styled.div`
  max-width: 500px;
  margin-bottom: 20px;
`;

const StyledButton = styled.button`
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  padding: 0px 30px;
  height: 40px;
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
  border: none;
  border: 1px solid white;
  outline: none;

  &:hover {
    background-color: white;
    color: ${(props) => props.color};
    border: ${(props) => "1px solid " + props.color};
  }
`;

export default AddSchedule;
