
export const sample = [{"type": "t1", "text": "Enhancing Role Clarity and Communication: A Two-Step Solution"},
{"type": "t2", "text": "Step 1: Establish a Clear Change Narrative"},
{"type": "p", "text": "Objective: To provide employees with a well-articulated vision of the future that explains the reasons and benefits behind organizational changes."},
{"type": "t3", "text": "Consider the Following Actions"},
{"type": "b", "text": "Develop a Unifying Change Story. As a leader, create a compelling narrative around the organizational changes that connects back to the company's core mission. This story should be communicated consistently across the company and serve as a foundation for all change-related communication. Example prompt for managers: 'How can we relate the changes in our department back to the company's core mission and the future vision we are trying to achieve?'"},
{"type": "b", "text": "Communicate Regularly and Transparently. Continuously provide updates throughout the change process, making sure each communication is in line with the overall change narrative. Admit when certain information is not yet available and commit to transparent updates. Example question for managers: 'What information can we share now, and how can we keep our team informed as more details become available?'"},
{"type": "b", "text": "Highlight the 'What’s in it for me?' Factor. Ensure that each employee understands the personal benefits and opportunities that the change brings. Explain how the change aligns with both team goals and individual career aspirations. Example prompt for managers: 'Can you clearly outline how this change will offer new opportunities for individual growth within our team?'"},
{"type": "t2", "text": "Step 2: Reinforce Clear Roles and Expectations"},
{"type": "p", "text": "Objective: To eliminate role ambiguity and empower employees through the clarification of each individual’s role, responsibilities, and performance expectations within the team."},
{"type": "t3", "text": "Consider the Following Actions"},
{"type": "b", "text": "Clarify Team Purpose and Goals. Define the fundamental reason for your team's existence and set clear, measurable goals that align with the company's mission. Regularly discuss how team behaviors and decisions are contributing to these goals. Example response for managers: 'Let's talk about how our daily work supports our primary purpose and how each goal demonstrates our contribution.'"},
{"type": "b", "text": "Establish Individual Goals and Priorities. Create specific individual goals that tie into team goals. Work with team members to define their most critical tasks and empower them to prioritize their work accordingly. Example question for managers: 'Do you understand how your personal goals benefit our team and support the wider organizational objectives?'"},
{"type": "b", "text": "Embrace Cross-Functionality and Engagement. Involve employees in cross-functional projects to increase exposure to diverse perspectives and reduce siloed thinking. Recognize and reward both in-role and extra-role performances that contribute to the overall well-being of the organization. Example prompt for managers: 'How can we involve our team in broader projects to foster engagement and a better understanding of the company’s goals?'"},
{"type": "END", "text": ""}]