import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import List from './List'
import Template from './Template'
import { Header, Icon, Button } from "semantic-ui-react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";

const URL = 'story-templates'

const StoryTemplates = () =>{
  const [orgName, setOrgName] = useState(null);
  const [loadData, setLoadData] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [newResponse, setNewResponse] = useState(0)

  function BackArrow() {
    navigate(`/app/${URL}/`);
  }

  function GetNew() {
    setNewResponse(Math.random())
    navigate(`/app/story-templates/create/`);
  }

  const { get_report_template, errors } = useSelector(
    (state) => ({
      get_report_template: state.report_template,
      errors: state.errors,
    }),
    shallowEqual
  );

  useEffect(() => {
    setLoadData(true);
    const timer1 = setTimeout(() => {
      setLoadData(get_report_template?.pending);
    }, 500);
    return () => clearTimeout(timer1);
  }, [get_report_template.pending]);

    return (
        <>
        <HeaderContainer>
            <HeaderText as="h2" onClick={BackArrow}>
                <Icon
                style={{ cursor: "pointer" }}
                onClick={BackArrow}
                name="home"
                />
                Story Templates
            </HeaderText>
            {location?.pathname !== "/app/story-templates/create/" && 
            <AddNewButton
            disabled={loadData}
            primary
            onClick={GetNew}
            >
            Create New
            </AddNewButton>}

        </HeaderContainer>
        <Routes>
          <Route path="/" element={<List newResponse={newResponse} />} />
          <Route path="details/:id" element={<Template newResponse={newResponse} setNewResponse={setNewResponse} />} />
          <Route path="create" element={<Template newResponse={newResponse} setNewResponse={setNewResponse} />} />
        </Routes>
      </>
    )
}


export default StoryTemplates

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;

const HeaderText = styled(Header)`
  margin-top: 40px;
  cursor: pointer;
`;

const AddNewButton = styled(Button)`
  display: ${({ hide }) => (hide ? "none" : "block")};
`;