import styled, { keyframes } from 'styled-components';
import React from "react";
import {Icon} from 'semantic-ui-react'

const SelectItem = ({title,item,selected,setSelected,hide}) =>{


    return (
        <Container selected={selected} onClick={()=>setSelected()} hide={hide&&!selected}>

            <Title>
                {title}
            </Title>

            <ItemContainer>
                {makeFirstPartBold(item)}
            </ItemContainer>

            {selected && <IconContainer>
           <Icon name="check"  />
            </IconContainer> }


        </Container>
    )

}

export default SelectItem

function makeFirstPartBold(inputString) {
    // Find the index of the first colon ":"
    const colonIndex = inputString.indexOf(':');
    
    // Check if a colon was found and it's not at the beginning of the string
    if (colonIndex > 0) {
      // Split the string into two parts at the colon
      const firstPart = inputString.slice(0, colonIndex + 1); // Include the colon
      const secondPart = inputString.slice(colonIndex + 1);
      
      // Combine the bolded first part with the second part
      return (<span>
        <strong>{firstPart}</strong>
        {secondPart}
      </span>)
    }
    
    // If no colon found or it's at the beginning, return the original string
    return inputString;
  }

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    cursor:pointer;
    margin-right:20px;
    font-family:'Raleway';
    margin-top:20px;
    border:1px solid #2A3039;
    padding:20px;
    position:relative;
    display:${props=>props.hide?'none':'flex'};

    border:${props=>props.selected?'2px solid #27CDA7':''};
    background-color:${props=>props.selected?'#EAFBF7':''};

    &:hover{
        background-color:#EAFBF7;
    }

`

const Title = styled.div`
    font-size:14px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:5px;
    display:flex;
    align-items:center;
`

const ItemContainer = styled.div`
font-size:12px;
color:#2A3039;
margin-top:5px;
`


const IconContainer = styled.div`
    position:absolute;
    top:20px;
    right:20px;
    display:flex;
    align-items:center;
    color:#27CDA7;
    font-size:20px;
`