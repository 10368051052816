import {
  UPDATE_GPT_RECOMMENDATION_PAGE_NUMBER,
  GET_GPT_RECOMMENDATION_PENDING,
  GET_GPT_RECOMMENDATION,
  CLEAR_RANDOM_GPT_RECOMMENDATION,
  GET_RANDOM_GPT_RECOMMENDATION,
  DELETE_GPT_RECOMMENDATION,
  FETCH_GPT_RECOMMENDATION,
  EDIT_GPT_RECOMMENDATION,
  ADD_GPT_RECOMMENDATION,
  CLEAR_GPT_RECOMMENDATION,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  gpt_recommendation: [],
  random_recommendation: [],
  deleted: null,
  created: null,
  updated: null,
  pending: false,
  pageNumber: 1,
};

export default function GptRecommendation(state = initialState, action) {
  switch (action.type) {
    case GET_GPT_RECOMMENDATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    
      case CLEAR_RANDOM_GPT_RECOMMENDATION:
        return {
          ...state,
          random_recommendation: [],
          pending: false,
        };
    case GET_RANDOM_GPT_RECOMMENDATION:
      return {
        ...state,
        random_recommendation: action.payload,
        pending: false,
      };
      
    case UPDATE_GPT_RECOMMENDATION_PAGE_NUMBER:
      return {
          ...state,
          pageNumber: action.payload,
        };

    case GET_GPT_RECOMMENDATION:
      return {
        ...state,
        gpt_recommendation: action.payload,
        pending: false,
      };

    case FETCH_GPT_RECOMMENDATION:
      return { 
          ...state, 
          [action.payload.id]: action.payload, 
          pending: false 
        };

    case EDIT_GPT_RECOMMENDATION:
      return { 
        ...state, 
        updated: action.payload 
      };

    case DELETE_GPT_RECOMMENDATION:
      return {
        ...state,
        deleted: action.payload,
      };

    case ADD_GPT_RECOMMENDATION:
      return {
        ...state,
        created: action.payload,
      };

    case CLEAR_GPT_RECOMMENDATION:
      return {
        ...state,
        ...initialState,
      };

    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
