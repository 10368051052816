import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { SG_FETCH_DEBRIEF_SCHEDULE_SUMMARY } from "constants/actions";

import { Button, Modal, Icon } from 'semantic-ui-react'


export default function SurveySummary({ debriefDetails }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [listOfResponses, setListOfResponses] = useState([])

  useEffect(() => {
    if (debriefDetails?.id) {
      dispatch({
        type: SG_FETCH_DEBRIEF_SCHEDULE_SUMMARY,
        payload: {
          id: debriefDetails?.id,
        },
      });
    }

  }, [dispatch, debriefDetails]);

  const { get_brief_summary,

  } = useSelector(
    (state) => ({
      get_brief_summary: state.debrief_schedule?.summary,
    }),
    shallowEqual
  );


  useEffect(() => {
    let _ListOfResponses = []
    if (get_brief_summary?.response) {
      _ListOfResponses = [{
        name: "Not Responded",
        response: get_brief_summary?.response?.not_responded_to_survey,
      },
      {
        name: "Not Completed",
        response: get_brief_summary?.response?.incomplete_response,
      },
      {
        name: "Completed",
        response: get_brief_summary?.response?.valid_response_found,
      },
      {
        name: "Summary",
        response: get_brief_summary?.response?.summary,
      }]
    }

    setListOfResponses(_ListOfResponses)

  }, [get_brief_summary]);

  function SetDispVal(e) {
    if (showDetails === e) {
      setShowDetails(false)
    } else {
      setShowDetails(e)
    }
  }


  return (<Modal
    closeIcon
    onClose={() => setOpen(false)}
    onOpen={() => setOpen(true)}
    open={open}
    trigger={<Button floated='right' color='violet'>
      Survey Summary
    </Button>}
  >
    <Modal.Description>
      <OuterContainer>
        {listOfResponses.map((res, i) => {
          return <Container key={i}>

            <ListContainer>
              <HeaderDiv>{res.name} <Icon onClick={() => SetDispVal(res.name)}
                name={showDetails === res.name ? 'minus' : 'add'} /></HeaderDiv>
              <ContentDiv show={showDetails === res.name ? true : false}>
                <pre>
                  {JSON.stringify(res.response, null, 2)}
                </pre>
              </ContentDiv>
            </ListContainer>
          </Container>
        })}
      </OuterContainer>
    </Modal.Description>
  </Modal>

  )

}
const OuterContainer = styled.div`

  margin: 20px 10px
`

const Container = styled.div`
  display: flex;
padding: 0px 25px;
`
const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`

const ContentDiv = styled.div`
  background-color: #dcdcdc;
  width: 100%;
  font-size: smaller;
  display: ${(props) => (props.show ? "block" : "none")};

`